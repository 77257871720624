#sub .golfgogo .sponsor{
	width:100%;
	display:inline-block;
	text-align: center;
}

#sub .golfgogo .sponsor .intro-text{
	/* width:60%; */
	padding: 0px 0px 160px 0px;

	font-size:35px;
	/* font-size:2.3rem; */
	font-family: 'Gowun Batang', serif;
	color: #009dc0;
	word-break: keep-all;
}

#sub .golfgogo .sponsor .sponsor-box{
  padding: 0px 0;
	/* width:60%; */
}

#sub .golfgogo .sponsor .sponsor-box .sponsor-img{
	/* width:60%; */
	width:50%;
	float:left;
    text-align: center;
}

#sub .golfgogo .sponsor .sponsor-box .sponsor-img img{
	/* width:60%; */
	width:70%;
}

#sub .golfgogo .sponsor .sponsor-text{
	text-align: left;
	width:50%;
	float:left;
	word-break: keep-all;
    word-spacing: 2px;
    margin-top: 60px;
}

#sub .golfgogo .sponsor .sponsor-text p{
	/* width:60%; */
	font-size: 20px;
	font-weight: 300;
}


#sub .golfgogo .sponsor .sponsor-text p .sponsor-text-span{
	/* width:60%; */
	font-size: 25px;
	font-weight: 600;
	font-family: 'Gowun Batang', serif;
}


.clear-both {
    clear: both;
}

#sub .golfgogo .sponsor .sponsor-box-sec{
    padding-top: 100px;
    word-break: keep-all;
    word-spacing: 2px;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir{
    width: 100%;
    padding: 50px 0px;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .sponsor-tel img{
    width: 8%;
    margin-bottom: 25px;

}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .sponsor-tel span.title{
    font-weight: 400;
    font-size: 50px;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir p{
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir span.sub{
    font-weight: 300;
    font-size: 30px;
    padding-bottom: 120px;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box{
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box .purpose-element{
    width: 300px;
    height: 300px;
    text-align: center;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box .purpose-element img{
    width: 100%;
    height: 300px;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box .purpose-element p{
    font-size: 20px;
    margin-top: 30px;
}

#sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bsec{
    float: left;
    width: 50%;
    background-color: green;
}

.sponsor_purpose {
    margin-top: 50px;
    font-weight: 300;
    font-size: 30px;
    text-align: left;
}

.sponsor_purpose li{
    list-style: disc;
    word-break: break-all;
}

#sub .golfgogo .sponsor .sponsor-box-thir {
    margin-top: 200px;
}

#sub .golfgogo .sponsor .sponsor-box-thir .sponsor-tel {
    font-size: 50px;
    font-weight: 400;
}

#sub .golfgogo .sponsor .sponsor-box-thir .sub {
    font-size: 40px;
    font-weight: 200;
    margin-top: 50px;
}



@media screen and (max-width: 1280px) {

}

@media screen and (max-width: 767px) {

    #sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box{
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
    }


    #sub .golfgogo .sponsor .sponsor-box .sponsor-img{
        /* width:60%; */
        text-align: center;
        width:100%;
    }
    
    #sub .golfgogo .sponsor .sponsor-box .sponsor-img img{
        width:100%;
    }

    #sub .golfgogo .sponsor .sponsor-text {
        width: 100%;
    }
    
    #sub .golfgogo .sponsor .sponsor-text p .sponsor-text-span{
        font-size: 20px;
    }

    #sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir{
        width: 100%;
        padding: 0px 0px 50px 0px;

    }

    .sponsor_purpose {
        padding: 0px 30px;
        margin-top: 20px;
        font-weight: 300;
        font-size: 20px;
        text-align: left;
    }
    
    .sponsor_purpose li{
        list-style: disc;
    }

    #sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box .purpose-element{
        width: 100%;
        height: auto;
        margin-top: 0px;
    }

    .el-margin-top{
        margin-top: 100px !important;
    }

    #sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box .purpose-element img{        width: 100%;
        width: 50%;
    }

    #sub .golfgogo .sponsor .sponsor-box-sec .sponsor-cont-bfir .purpose-box .purpose-element p {
        margin-top: 0px;
    }

    #sub .golfgogo .sponsor .sponsor-box-thir {
        margin: 100px 0px ;
    }

    #sub .golfgogo .sponsor .sponsor-box-thir .sub{
        font-size: 30px;
    }

    
}

