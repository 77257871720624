/*common*/

@media screen and (max-width: 1180px) {
		
	#sub{
		width:100%;
    padding:0px 3%;
		box-sizing:border-box;
		overflow:hidden;
	}

	
	#sub .subQTline .quickMenu{
	}

	#sub .subQTline .quickMenu li{
		width:20%;
	}
	
	#sub .subQTline .quickMenu li .ico{
		width:34px;
		margin:0 auto;
	}
	
	#sub .subQTline .quickMenu li .ico img{
		width:100%;
	}
	
	#sub .subQTline .quickMenu li .text{
		font-size:15PX;
		text-align:center;
		color:#444;
		margin-top:5px;
	}
	
	#sub .subQTline .nav{
		display:inline-block;
		float:Right;
		margin-top:22px;
	}
	
	#sub .subQTline .nav .ico{
		display:inline-block;
		width:45px;
		vertical-align:middle;
	}
	
	#sub .subQTline .nav .tx{
		display:inline-block;
		font-size:15px;
		vertical-align:middle;
		color:#777;
		font-family:'lato','nanumsquare';
	}
	
	#sub .subQTline .nav div{
		position:relative;
		margin:0px 15px;
	}
	
	
	#sub .subQTline .nav div:last-child{
		margin:0px 0px 0px 15px;
	}
	
	#sub .subQTline .nav div:after{
		content:'';
		background:url(../images/sub/navArrow.png);
		width:7px;
		height:13px;
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		margin-left:15px;
	}
	
	
	#sub .subQTline .nav div:last-child:after{
		/* ; */
	}

	#sub .pageInnerTitle {
		text-align: center;
		padding: 100px 0px 0px 0px;
	}
	
	#sub .paging{
		width:100%;
		text-align:center;
		margin-top:50px;
	}
	
	#sub .paging li{
		width:24px;
		line-height:24px;
		display:inline-block;
		margin:0px 5px;
	}
	
	#sub .paging li a{
		color:#c1c1c1;
	}
	
	#sub .paging .on a{
		color:#004f86;
		text-decoration:underline;
		font-weight:bold;
		
	}
	
	#sub .paging li:first-child,
	#sub .paging li:last-child{
		border:1px solid #e5e5e5;
		box-sizing:border-box;
	}
	
	
	#sub .paging li img{
		width:100%;
	}


		
	#sub .subTitleImg{
		width:100%;
		position:relative;
	}
	
	#sub .subTitleImg .bg{
		width:100%;
	}
	
	#sub .subTitleImg .bg img{
		width:100%;
	}
	
	#sub .subTitleImg .textBox{
		width:50%;
		position:absolute;
		background:rgba(0,0,0,0.5);
		padding:30px 40px;
		box-sizing:border-box;
		bottom:0;
	}
	
	#sub .subTitleImg .textBox .lcNm{
		font-size:15px;
		color:#fff;
		font-family:'lato','nanumsquare'
	}
	
	#sub .subTitleImg .textBox .tit{
		font-size:26px;
		color:#fff;
		font-weight:bold;
		margin-top:30px;
		word-break:keep-all;
		line-height:34px;
	}
	
	#sub .subTitleImg .textBox .subT{
		font-size:15px;
		color:#fff;
		margin-top:15px
	}

	#sub .subTitleArea{
		width:100%;
	}
	
	#sub .subTitleArea .tit h1 {
		font-size:28px;
		text-align:center;
		font-weight:bold;
		color:#222;
	}

  #sub .subTitleArea .tit .hrH1 {
    content: "";
    bottom: 0;
    width: 1px;
    height: 30px;
    margin: 20px 0 20px 50%;
    background: #999;
  }

/* 	#sub .lcMenu{
		width:100%;
		margin-top:1.5em;
		display:flex;
	}
	
	#sub .lcMenu a{
		width:100%;
		display:inline-block;
		border-right:1px solid #e5e5e5;
		border-top:1px solid #e5e5e5;
		border-bottom:1px solid #e5e5e5;
		Text-align:center;
		font-size:16px;
		padding:20px 0px;
	}
	
	#sub .lcMenu a li{
		color:#999;
	}
	
	
	#sub .lcMenu a:first-child{
		border-left:1px solid #e5e5e5;
	}
	
	#sub .lcMenu .on{
		border-bottom:0;
		color:#222;
		font-weight:bold;
	}
	
	#sub .lcMenu .on li{
		color:#222;
	} */

	#sub .lcMenu a{		
		font-size:16px;		
	}
	
	
	.deRadio{
		display:inline-block;
	}
	
	
	.deRadio label{
		display: inline-block;
		cursor: pointer;
		font-size: 14px;
		vertical-align:middle;
		color:#777777;
	}
	
	
	.deRadio input[type=radio]{
		display: none;
	}
	
	.deRadio label::before {
		content: "";
		display: inline-block;
		width: 20px;
		vertical-align:middle;
		height:20px;
		margin-right:5px;
		background:url("../images/sub/radioOff.png");
		margin-top:-2px;
		background-size:100%;
	}
	
	
	
	.deRadio input[type=radio]:checked + label::before {
		content: "";
		background:url("../images/sub/radioOn.png");
		background-size:100%;
	}

	

	#sub .dimenuArea{
		width:100%;
		margin-top:30px;
		/* display:inline-block; */
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;		
	}
	
/* 	#sub .dimenuArea .diMenu{
		display:inline-block;
	} */
	
	
	#sub .dimenuArea .diMenu li{
		width:150px;
		display:inline-block;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
		/* padding:16px 0px; */
		text-align:Center;
		margin-right:6px;
	}

/* 	#sub .dimenuArea .diMenu li a{
		display: block;
		padding:16px 16px;
	} */
	
	#sub .dimenuArea .diMenu li .ico{
		width:27px;
		display:inline-block;
		vertical-align:Middle;
	}
	
	#sub .dimenuArea .diMenu li .ico img{
		width:100%;
	}
	
	#sub .dimenuArea .diMenu li .text{
		font-size:15px;
		display:inline-block;
		vertical-align:Middle;
		margin-left:4px;
	}
	
	#sub .dimenuArea .bookingBtn{
		display:inline-block;
		float:right;
		width:150px;
		padding:20px 0px;
		background:#004f86;
		color:#fff;
		text-align:center;
	}
	#sub .dimenuArea .bookingBtn a{
		color:#fff;
	}

}


@media screen and (max-width: 767px) {
		
	#sub{
		width:100%;
		box-sizing:border-box;
	}

	#sub .subQTline .quickMenu{
		
	}
	
	#sub .subQTline .quickMenu li{
		height: 100px;
		padding: 10px 0px;
	}

	#sub .subQTline .quickMenu li .ico{
		width:34px;
		margin:0 auto;
	}
	
	#sub .subQTline .quickMenu li .ico img{
		width:100%;
	}
	
	#sub .subQTline .quickMenu li .text{
		font-size:15PX;
		text-align:center;
		color:#444;
		margin-top:5px;
	}

	#sub .pageInnerTitle {
		text-align: center;
		padding: 50px 0px 0px 0px;
	}
	
	#sub .pageInnerTitle span{
		text-align: center;
		border-bottom: 1px solid gray;
		font-size: 40px;
		font-weight: 500;
		padding: 10px 0px;
	}

	#sub .page-info .page-info-bg{
		overflow: hidden;
	}


	#sub .page-info .page-info-bg img {
		height: 300px;
		opacity: 0.9;
		width: 150%;
	}
	
	#sub .paging{
		width:100%;
		text-align:center;
		margin-top:50px;
	}
	
	#sub .paging li{
		width:24px;
		line-height:24px;
		display:inline-block;
		margin:0px 5px;
	}
	
	#sub .paging li a{
		color:#c1c1c1;
	}
	
	#sub .paging .on a{
		color:#004f86;
		text-decoration:underline;
		font-weight:bold;
		
	}
	
	#sub .paging li:first-child,
	#sub .paging li:last-child{
		border:1px solid #e5e5e5;
		box-sizing:border-box;
	}
	
	
	#sub .paging li img{
		width:100%;
	}

	
	
	
			
	#sub .subTitleImg{
		width:100%;
		position:relative;
	}
	
	#sub .subTitleImg .bg{
		width:100%;
		overflow:hidden;
	}
	
	#sub .subTitleImg .bg img{
		width:180%;
		position:relative;
		left:50%;
		transform:translateX(-50%);
		z-index:-1;
	}
	
	#sub .subTitleImg .textBox{
		width:90%;
		position:absolute;
		background:rgba(0,0,0,0.5);
		padding:25px 20px;
		box-sizing:border-box;
		bottom:0;
	}
	
	#sub .subTitleImg .textBox .lcNm{
		font-size:13px;
		color:#fff;
		font-family:'lato','nanumsquare';
		display:none;
	}
	
	#sub .subTitleImg .textBox .tit{
		font-size:18px;
		color:#fff;
		font-weight:bold;
		margin-top:0px;
		word-break:keep-all;
		line-height:26px;
	}
	
	#sub .subTitleImg .textBox .subT{
		font-size:13px;
		color:#fff;
		margin-top:10px;
		word-break:keep-all;
		line-height:21px;
	}

	#sub .subTitleArea{
		width:100%;
	}
	
	#sub .subTitleArea .tit h1 {
		font-size:20px;
		text-align:center;
		font-weight:bold;
		color:#222;
	}
	
  #sub .subTitleArea .tit .hrH1 {
    content: "";
    bottom: 0;
    width: 1px;
    height: 30px;
    margin: 20px 0 20px 50%;
    background: #999;
  }
	
/* 	#sub .lcMenu{
		width:100%;
		margin-top:1.5em;
		display:flex;
	}
	
	#sub .lcMenu li{
		width:100%;
		display:inline-block;
		border-right:1px solid #e5e5e5;
		border-top:1px solid #e5e5e5;
		border-bottom:1px solid #e5e5e5;
		Text-align:center;
		font-size:14px;
		padding:18px 0px;
	}
	
	#sub .lcMenu li a{
		color:#999;
	}
	
	
	#sub .lcMenu li:first-child{
		border-left:1px solid #e5e5e5;
	}
	
	#sub .lcMenu .on{
		border-bottom:0;
		color:#222;
		font-weight:bold;
	}
	
	#sub .lcMenu .on a{
		color:#222;
	} */
/* 	#sub .lcMenu{
		width:100%;
		margin-top:1.5em;
		display:flex;
	}
	
	#sub .lcMenu li{
		width:100%;
		display:inline-block;
		border-right:1px solid #e5e5e5;
		border-top:1px solid #e5e5e5;
		border-bottom:1px solid #e5e5e5;
		Text-align:center;
		font-size:14px;
		padding:18px 0px;
	}
	
	#sub .lcMenu li a{
		color:#999;
	}
	
	
	#sub .lcMenu li:first-child{
		border-left:1px solid #e5e5e5;
	}
	
	#sub .lcMenu .on{
		border-bottom:0;
		color:#222;
		font-weight:bold;
	}
	
	#sub .lcMenu .on a{
		color:#222;
	} */
	
	
		
		
	
	.deRadio{
		display:inline-block;
	}
	
	
	.deRadio label{
		display: inline-block;
		cursor: pointer;
		font-size: 13px;
		vertical-align:middle;
		color:#777777;
	}
	
	
	.deRadio input[type=radio]{
		display: none;
	}
	
	.deRadio label::before {
		content: "";
		display: inline-block;
		width: 16px;
		vertical-align:middle;
		height:16px;
		margin-right:5px;
		background:url("../images/sub/radioOff.png");
		margin-top:-2px;
		background-size:100%;
	}
	
	
	
	.deRadio input[type=radio]:checked + label::before {
		content: "";
		background:url("../images/sub/radioOn.png");
		background-size:100%;
	}


	#sub .dimenuArea{
		width:100%;
		margin-top:30px;
		/* display:inline-block; */
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		flex-direction: row-reverse;
	}
	
	#sub .dimenuArea .diMenu{
		display:inline-block;
		width:100%;
		
	}
	
	
	#sub .dimenuArea .diMenu li{
		width:32.5%;
		display:inline-block;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
/* 		padding:16px 0px; */
		text-align:Center;
		margin-right:1.2%;
		float:left;
	}
	
	#sub .dimenuArea .diMenu li:last-child{
		margin-right:0%;
	}
	
	
	#sub .dimenuArea .diMenu li .ico{
		width:18px;
		display:inline-block;
		vertical-align:Middle;
	}
	
	#sub .dimenuArea .diMenu li .ico img{
		width:100%;
	}
	
	#sub .dimenuArea .diMenu li .text{
		font-size:12px;
		display:inline-block;
		vertical-align:Middle;
		margin-left:2px;
	}
	
	#sub .dimenuArea .bookingBtn{
	/*	display:inline-block;
	 	float:right;
		width:33%;
		padding:16px 0px; */
		background:#004f86;
		color:#fff;
		text-align:center;
		font-size:13px;
		margin-top:10px;
	}
	#sub .dimenuArea .bookingBtn a{
		color:#fff;
	}
	
}




/*boardList*/

@media screen and (max-width: 1180px) {
	#sub .bbCont{
		width:100%;
		margin:5em auto 5em;
	}
	
	#sub .bbCont .subjA{
		width:100%;
		display:inline-block;
	}
	
	#sub .bbCont .subjA .ensubj{
		font-size:20px;
		color:#444;
	}
	
	#sub .bbCont .subjA .subj{
		font-size:36px;
		color:#222;
		margin-top:10px;
	}
	
	
	#sub .bbCont .boardListArea{
		width:100%;
		margin-top:1.5em;
	}
	
	#sub .bbCont .boardListArea .searchBox{
		width:100%;
		background:#f7f7f7;
		padding:20px 20px;
		box-sizing:border-box;
		text-align:center;
	}
	
	#sub .bbCont .boardListArea .searchBox .selectbox{
		width:17%;
		padding:15px;
		box-sizing:border-box;
		border-bottom:1px solid #222;
		border-left:0;
		border-right:0;
		border-top:0;
		font-size:16px;
		color:#222;
		background:#f7f7f7;
	}
	
	#sub .bbCont .boardListArea .searchBox .textbox{
		width:65%;
		padding:15px;
		box-sizing:border-box;
		border-bottom:1px solid #222;
		border-left:0;
		border-right:0;
		border-top:0;
		font-size:16px;
		color:#222;
		background:#f7f7f7;
		margin-left:0.5%;
	}
	
	#sub .bbCont .boardListArea .searchBox .searchbtn{
		width:15%;
		display:inline-block;
		border-radius:50px;
		background:#004f86;
		color:#fff;
		text-align:center;
		font-size:16px;
		padding:15px 0px;
		margin-left:1%;
	}
	
	#sub .bbCont .boardListArea .total{
		width:100%;
		font-size:15px;
		color:#999;
		margin-top:50px;
	}
	
	#sub .bbCont .boardListArea .boardList{
		width:100%;
		margin-top:20px;
	}
	
	#sub .bbCont .boardListArea .boardList li,
	#sub .bbCont .boardListArea .boardList li a{
		width:100%;
		display:flex;
	}
	
	#sub .bbCont .boardListArea .boardList li{
		padding:25px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .bbCont .boardListArea .boardList li:first-child{
		border-top:1px solid #222;
	}
	
	
	#sub .bbCont .boardListArea .boardList li div{
		text-align:center;
		font-size:15px;
	}
	
	
	#sub .bbCont .boardListArea .boardList li .num{
		width:8%;
		display:inline-block;
	}
	
	
	#sub .bbCont .boardListArea .boardList .head .subj{
		text-align:center;
	}
	
	
	#sub .bbCont .boardListArea .boardList li .subj{
		width:70%;
		display:inline-block;
		text-align:left;
	}
	
	#sub .bbCont .boardListArea .boardList li .subj .subject{
		display:inline-block;
		text-align:left;
		vertical-align:middle;
		max-width:90%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	
	#sub .bbCont .boardListArea .boardList li .subj .new{
		display:inline-block;
		text-align:left;
		vertical-align:middle;
		width:16px;
		margin-left:5px;
	}
	
	#sub .bbCont .boardListArea .boardList li .subj .new img{
		width:100%;
	}
	
	#sub .bbCont .boardListArea .boardList li .writer{
		width:15%;
		display:inline-block;
	}
	
	#sub .bbCont .boardListArea .boardList li .date{
		width:15%;
		display:inline-block;
	}
	
	#sub .bbCont .boardListArea .boardList li .view{
		width:10%;
		display:inline-block;
		display:none;
	}


}


@media screen and (max-width: 767px) {
	#sub .bbCont{
		width:100%;
		margin:5em auto 5em;
	}
	
	#sub .bbCont .subjA{
		width:100%;
		display:inline-block;
	}
	
	#sub .bbCont .subjA .ensubj{
		font-size:20px;
		color:#444;
	}
	
	#sub .bbCont .subjA .subj{
		font-size:36px;
		color:#222;
		margin-top:10px;
	}
	
	
	#sub .bbCont .boardListArea{
		width:100%;
		margin-top:1.5em;
	}
	
	#sub .bbCont .boardListArea .searchBox{
		width:100%;
		background:#f7f7f7;
		padding:1em;
		box-sizing:border-box;
		text-align:center;
		display:inline-block;
	}
	
	#sub .bbCont .boardListArea .searchBox .selectbox{
		width:30%;
		padding:15px;
		box-sizing:border-box;
		border-bottom:1px solid #222;
		border-left:0;
		border-right:0;
		border-top:0;
		font-size:13px;
		color:#222;
		background:#f7f7f7;
	}
	
	#sub .bbCont .boardListArea .searchBox .textbox{
		width:67%;
		padding:15px;
		box-sizing:border-box;
		border-bottom:1px solid #222;
		border-left:0;
		border-right:0;
		border-top:0;
		font-size:13px;
		color:#222;
		background:#f7f7f7;
		margin-left:1%;
	}
	
	#sub .bbCont .boardListArea .searchBox .searchbtn{
		width:100%;
		display:inline-block;
		border-radius:5px;
		background:#004f86;
		color:#fff;
		text-align:center;
		font-size:13px;
		padding:15px 0px;
		margin-left:0%;
		float:right;
		margin-top:15px;
	}
	
	#sub .bbCont .boardListArea .total{
		width:100%;
		font-size:15px;
		color:#999;
		margin-top:50px;
	}
	
	#sub .bbCont .boardListArea .boardList{
		width:100%;
		margin-top:20px;
	}
	
	#sub .bbCont .boardListArea .boardList li,
	#sub .bbCont .boardListArea .boardList li a{
		width:100%;
		display:flex;
	}
	
	#sub .bbCont .boardListArea .boardList li{
		padding:25px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .bbCont .boardListArea .boardList li:first-child{
		border-top:1px solid #222;
	}
	
	
	#sub .bbCont .boardListArea .boardList li div{
		text-align:center;
		font-size:13px;
	}
	
	
	#sub .bbCont .boardListArea .boardList li .num{
		width:8%;
		display:inline-block;
		display:none;
	}
	
	
	#sub .bbCont .boardListArea .boardList .head .subj{
		text-align:center;
	}
	
	
	#sub .bbCont .boardListArea .boardList li .subj{
		width:75%;
		display:inline-block;
		text-align:left;
	}
	
	#sub .bbCont .boardListArea .boardList li .subj .subject{
		display:inline-block;
		text-align:left;
		vertical-align:middle;
		max-width:86%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	
	#sub .bbCont .boardListArea .boardList li .subj .new{
		display:inline-block;
		text-align:left;
		vertical-align:middle;
		width:16px;
		margin-left:5px;
	}
	
	#sub .bbCont .boardListArea .boardList li .subj .new img{
		width:100%;
	}
	
	#sub .bbCont .boardListArea .boardList li .writer{
		width:25%;
		display:inline-block;
	}
	
	#sub .bbCont .boardListArea .boardList li .date{
		width:15%;
		display:inline-block;
		display:none;
	}
	
	#sub .bbCont .boardListArea .boardList li .view{
		width:10%;
		display:inline-block;
		display:none;
	}


}





/*boardView*/


@media screen and (max-width: 1180px) {
	
	#sub .bbCont .boardViewArea{
		width:100%;
		margin-top:1.5em;
	}
	
	#sub .bbCont .boardViewArea .diArea{
		width:100%;
		padding:2em 1em;
		box-sizing:border-box;
		display:inline-block;
		border-top:1px solid #222;
		margin-left: .5rem;
	}
	
	#sub .bbCont .boardViewArea .diArea .subjA{
		display:inline-block;
		width:100%;
		vertical-align:bottom;
	}
	
	#sub .bbCont .boardViewArea .diArea .subjA .noticeBat{
		width:55px;
		background:#004f86;
		color:#fff;
		text-align:Center;
		font-size:14px;
		padding:5px 0px;
		border-radius:50px;
	}
	
	#sub .bbCont .boardViewArea .diArea .subjA .subj{
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-size:20px;
		font-weight:bold;
		margin-top:15px;
	}
	
	#sub .bbCont .boardViewArea .diArea .iList{
		display:inline-block;
		width:100%;
		text-align:left;
		vertical-align:bottom;
		margin-top:20px;
	}
	
	
	#sub .bbCont .boardViewArea .diArea .iList li{
		display:inline-block;
		padding:0px 10px;
		border-right:1px solid #e5e5e5;
		font-size:14px;
	}
	
	#sub .bbCont .boardViewArea .diArea .iList li:last-child{
		padding:0px 0px 0px 10px;
		border-right:0px solid #e5e5e5;
	}
	
	#sub .bbCont .boardViewArea .diArea .iList li:first-child{
		padding:0px 10px 0px 0px;
	}
	
	#sub .bbCont .boardViewArea .viewBox{
		width:100%;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
	}
	
	#sub .bbCont .boardViewArea .viewBox .viewCont{
		width:100%;
		padding:50px 30px;
		box-sizing:border-box;
	}
	
	
	#sub .bbCont .boardViewArea .viewBox .viewCont p{
		margin:0 auto;
		line-height:26px;
	}
	
	
	#sub .bbCont .boardViewArea .viewBox .viewCont .center{
		font-size:16px;
		color:#444;
		text-align:center;
	}
	
	
	
	
	#sub .bbCont .boardViewArea .viewBox .viewCont img{
		text-align:Center;
		max-width:1020px;
		width:100%;
		margin:50px auto 50px;
	}
	
	
	
	
	
	#sub .bbCont .boardViewArea .viewBox .btnArea{
		width:100%;
		padding:30px;
		background:#f7f7f7;
		border-top:1px solid #e5e5e5;
		box-sizing:border-box;
		text-align:right;
	}
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li{
		display:inline-block;
		background:#fff;
		border-radius:50px;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
		
	}

	#sub .bbCont .boardViewArea .viewBox .btnArea li a {
		display: block;
		padding:15px 30px;
		cursor: pointer;
	}
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li .ico{
		width:17px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li .ico img{
		width:100%;
	}
	
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li .tx{
		font-size:16px;
		display:inline-block;
		vertical-align:Middle;
		margin-left:10px;
		color:#444;
	}
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li .tx{
		font-size:16px;
		display:inline-block;
		vertical-align:Middle;
		margin-left:10px;
		color:#444;
	}
	
	#sub .bbCont .boardViewArea .boardListArea{
		margin-top:0px;
	}
	
	#sub .bbCont .boardViewArea .boardListArea .boardList{
		margin-top:0px;
	}
	
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li:first-child{
		border-top:0;
	}
	
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li .num{
		width:15%;
	}
	
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li .subj{
		width:75%;
	}
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li .date{
		width:20%;
	}

}


@media screen and (max-width: 767px) {
	
	#sub .bbCont .boardViewArea{
		width:100%;
		margin-top:1.5em;
	}
	
	#sub .bbCont .boardViewArea .diArea{
		width:100%;
		padding:2em 1em;
		box-sizing:border-box;
		display:inline-block;
		border-top:1px solid #222;
	}
	
	#sub .bbCont .boardViewArea .diArea .subjA{
		display:inline-block;
		width:100%;
		vertical-align:bottom;
	}
	
	#sub .bbCont .boardViewArea .diArea .subjA .noticeBat{
		width:55px;
		background:#004f86;
		color:#fff;
		text-align:Center;
		font-size:14px;
		padding:5px 0px;
		border-radius:50px;
	}
	
	#sub .bbCont .boardViewArea .diArea .subjA .subj{
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-size:20px;
		font-weight:bold;
		margin-top:15px;
	}
	
	#sub .bbCont .boardViewArea .diArea .iList{
		display:inline-block;
		width:100%;
		text-align:left;
		vertical-align:bottom;
		margin-top:20px;
	}
	
	
	#sub .bbCont .boardViewArea .diArea .iList li{
		display:inline-block;
		padding:0px 10px;
		border-right:1px solid #e5e5e5;
		font-size:14px;
	}
	
	#sub .bbCont .boardViewArea .diArea .iList li:last-child{
		padding:0px 0px 0px 10px;
		border-right:0px solid #e5e5e5;
	}
	
	#sub .bbCont .boardViewArea .diArea .iList li:first-child{
		padding:0px 10px 0px 0px;
	}
	
	#sub .bbCont .boardViewArea .viewBox{
		width:100%;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
	}
	
	#sub .bbCont .boardViewArea .viewBox .viewCont{
		width:100%;
		padding:50px 1.5em;
		box-sizing:border-box;
	}
	
	
	#sub .bbCont .boardViewArea .viewBox .viewCont p{
		margin:0 auto;
		line-height:21px;
		word-break:keep-all;
		font-size:13px;
		color:#444;
	}
	
	
	#sub .bbCont .boardViewArea .viewBox .viewCont .center{
		line-height:21px;
		word-break:keep-all;
		font-size:13px;
		text-align:center;
	}
	
	
	
	
	#sub .bbCont .boardViewArea .viewBox .viewCont img{
		text-align:Center;
		max-width:1020px;
		width:100%;
		margin:2.5em auto 2.5em;
	}
	
	
	
	
	
	#sub .bbCont .boardViewArea .viewBox .btnArea{
		width:100%;
		padding:1em 1.5em;
		background:#f7f7f7;
		border-top:1px solid #e5e5e5;
		box-sizing:border-box;
		text-align:right;
	}
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li{
		display:inline-block;
		background:#fff;
		border-radius:50px;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
		padding:0px;
	}

	#sub .bbCont .boardViewArea .viewBox .btnArea li a{
		padding:3px 10px;
	}
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li .ico{
		width:15px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li .ico img{
		width:100%;
	}
	
	
	#sub .bbCont .boardViewArea .viewBox .btnArea li .tx{
		font-size:14px;
		display:inline-block;
		vertical-align:Middle;
		margin-left:10px;
		color:#444;
	}
	
	#sub .bbCont .boardViewArea .boardListArea{
		margin-top:0px;
	}
	
	#sub .bbCont .boardViewArea .boardListArea .boardList{
		margin-top:0px;
	}
	
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li:first-child{
		border-top:0;
	}
	
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li .num{
		width:15%;
	}
	
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li .subj{
		width:75%;
	}
	
	#sub .bbCont .boardViewArea .boardListArea .boardList li .date{
		width:20%;
	}

}




/*bookingInfo*/

@media screen and (max-width: 1180px) {
	
	#sub .bbCont .booking{
		margin-top:1.5em;
		width:100%;
	}
	
	#sub .bbCont .booking .inBox{
		width:100%;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
		padding:1.5em;
	}
	#sub .bbCont .booking .inBox .tit{
		font-size:20px;
		color:#222;
		font-weight:bold;
	}
	
	#sub .bbCont .booking .inBox .inList{
		width:100%;
		margin-top:50px;
	}
	
	#sub .bbCont .booking .inBox .inList li{
		width:49.5%;
		display:inline-block;
		vertical-align:Middle;
	}
	
	#sub .bbCont .booking .inBox .inList li .ico{
		width:60px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .bbCont .booking .inBox .inList li .ico img{
		width:100%;
	}
	
	#sub .bbCont .booking .inBox .inList li .txA{
		display:inline-block;
		vertical-align:middle;
		margin-left:15px;
	}
	
	#sub .bbCont .booking .inBox .inList li .txA .subj{
		font-size:16px;
		color:#777777;
		font-family:'lato','nanumsquare';
	}
	
	#sub .bbCont .booking .inBox .inList li .txA .ct{
		font-size:18px;
		color:#222222;
		font-family:'lato','nanumsquare';
		margin-top:5px;
	}
	
	
	
	#sub .bbCont .booking .cancleTbox{
		width:100%;
		background:#f7f7f7;
		padding:30px;
		box-sizing:border-box;
		margin-top:20px;
	}
	
	#sub .bbCont .booking .cancleTbox .subjLine{
		width:100%;
	}
	
	#sub .bbCont .booking .cancleTbox .subjLine .ico{
		width:28px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .bbCont .booking .cancleTbox .subjLine .subj{
		font-size:20px;
		font-weight:bold;
		color:#222;
		display:Inline-block;
		vertical-align:Middle;
		margin-left:10px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .subs{
		font-size:15px;
		color:#444;
		margin-top:20px;
		word-break:keep-all;
		line-height:24px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .tList{
		width:100%;
		margin-top:1.5em;
		padding-left:20px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .tList li{
		width:100%;
		list-style:disc;
		margin-top:10px;
	}
	
	#sub .bbCont .booking .cancleTbox .tList li:first-child{
		margin-top:0px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .tList li .subj{
		font-size:15px;
		color:#222;
		font-weight:bold;
		display:inline-block;
		width:40%;
	}
	
	#sub .bbCont .booking .cancleTbox .tList li .subs{
		font-size:15px;
		color:#444;
		display:inline-block;
		width:59%;
		margin:0;
	}


}


@media screen and (max-width: 767px) {
	
	#sub .bbCont .booking{
		margin-top:1.5em;
		width:100%;
	}
	
	#sub .bbCont .booking .inBox{
		width:100%;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
		padding:1.5em;
	}
	#sub .bbCont .booking .inBox .tit{
		font-size:18px;
		color:#222;
		font-weight:bold;
		word-break:keep-all;
		line-height:26px;
	}
	
	#sub .bbCont .booking .inBox .inList{
		width:100%;
		margin-top:2em;
	}
	
	#sub .bbCont .booking .inBox .inList li{
		width:100%;
		display:inline-block;
		vertical-align:Middle;
		margin-top:15px;
	}
	
	#sub .bbCont .booking .inBox .inList li:first-child{
		margin-top:0px;
	}
	
	#sub .bbCont .booking .inBox .inList li .ico{
		width:45px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .bbCont .booking .inBox .inList li .ico img{
		width:100%;
	}
	
	#sub .bbCont .booking .inBox .inList li .txA{
		display:inline-block;
		vertical-align:middle;
		margin-left:15px;
	}
	
	#sub .bbCont .booking .inBox .inList li .txA .subj{
		font-size:13px;
		color:#777777;
		font-family:'lato','nanumsquare';
	}
	
	#sub .bbCont .booking .inBox .inList li .txA .ct{
		font-size:15px;
		color:#222222;
		font-family:'lato','nanumsquare';
		margin-top:5px;
	}
	
	
	
	#sub .bbCont .booking .cancleTbox{
		width:100%;
		background:#f7f7f7;
		padding:1.5em;
		box-sizing:border-box;
		margin-top:20px;
	}
	
	#sub .bbCont .booking .cancleTbox .subjLine{
		width:100%;
	}
	
	#sub .bbCont .booking .cancleTbox .subjLine .ico{
		width:28px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .bbCont .booking .cancleTbox .subjLine .subj{
		font-size:18px;
		font-weight:bold;
		color:#222;
		display:Inline-block;
		vertical-align:Middle;
		margin-left:10px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .subs{
		font-size:13px;
		color:#444;
		margin-top:1em;
		word-break:keep-all;
		line-height:21px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .tList{
		width:100%;
		margin-top:1.5em;
		padding-left:20px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .tList li{
		width:100%;
		list-style:disc;
		margin-top:10px;
	}
	
	#sub .bbCont .booking .cancleTbox .tList li:first-child{
		margin-top:10px;
	}
	
	
	#sub .bbCont .booking .cancleTbox .tList li .subj{
		font-size:13px;
		color:#222;
		font-weight:bold;
		display:inline-block;
		width:100%;
	}
	
	#sub .bbCont .booking .cancleTbox .tList li .subs{
		font-size:13px;
		color:#444;
		display:inline-block;
		width:100%;
		margin:5px;
	}


}





/*clarkPackageList01 - 패키지리스트*/


@media screen and (max-width: 1180px) {
	
	#sub .subCont{
		width:100%;
		margin:5em auto 5em;
	}
	
	#sub .subCont .container{
		width:100%;
		margin-top:4em;
	}
	
	#sub .subCont .container .lcOpLine{
		width:100%;
		display:inline-block;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu{
		display:inline-block;
		margin-top:7px;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu li{
		display:inline-block;
		font-size:16px;
		padding:0px 15px;
		border-left:1px solid #e5e5e5;
	}
	#sub .subCont .container .lcOpLine .lcD2_menu li a{
		color:#c1c1c1;
	}
	#sub .subCont .container .lcOpLine .lcD2_menu li:first-child{
		padding:0px 15px 0px 0px;
		border-left:0px solid #e5e5e5;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu .on a{
		color:#004f86;
		font-weight:bold;
	}
	
	#sub .subCont .container .lcOpLine .opA{
		display:inline-block;
		float:right;
		
	}
	
	
	#sub .subCont .container .lcOpLine .opA .alignList{
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .lcOpLine .opA .alignList li{
		display:inline-block;
		margin-left:10px;
	}
	
	
	#sub .subCont .container .lcOpLine .opA .listType{
		display:inline-block;
		vertical-align:middle;
		margin-left:20px;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType li{
		width:30px;
		display:inline-block;
		border:1px solid #444;
		box-sizing:border-box;
		float:left;
		opacity:0.2;
		margin-left:-1px;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType li img{
		width:100%;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType .on{
		opacity:1;
		
	}
	
	#sub .subCont .container .listType{
		width:100%;
		margin-top:15px;
	}
	
	#sub .subCont .container .listType dd{
		width:100%;
		padding:50px 0px;
		border-bottom:1px solid #e5e5e5;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .listType dd:first-child{
		border-top:1px solid #222;
	}
	
	
	
	#sub .subCont .container .listType dd .thum{
		width:30%;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .listType dd .thum img{
		width:100%;
	}
	
	
	#sub .subCont .container .listType dd .txA{
		width:69%;
		display:inline-block;
		vertical-align:middle;
		padding:0px 30px;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .listType dd .txA .subject{
		width:100%;
		font-size:18px;
		color:#222;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	#sub .subCont .container .listType dd .txA .subject span{
		font-weight:bold;
	}
	
	#sub .subCont .container .listType dd .txA .subs{
		width:100%;
		font-size:14px;
		color:#222;
		margin-top:10px;
		word-break:keep-all;
		line-height:21px;
	}
	
	#sub .subCont .container .listType dd .txA .price{
		width:100%;
		font-size:20px;
		font-weight:bold;
		font-family:'lato','nanumsquare';
		color:#d30000;
		margin-top:15px;
	}
	
	
	#sub .subCont .container .listType dd .txA .price .won{
		font-size:15px;
		font-weight:normal;
	}
	
	
	#sub .subCont .container .listType dd .txA .price .pr{
		font-size:16px;
		font-weight:normal;
		color:#777;
		display:inline-block;
		margin-left:10px;
	}
	
	
	#sub .subCont .container .listType dd .txA .ctSubs{
		font-size:15px;
		color:#444;
		margin-top:2em;
		word-break:keep-all;
		line-height:23px;
	}
	
	#sub .subCont .container .listType dd .txA .ctList{
		margin-top:2em;
		width:100%;
	}
	
	
	#sub .subCont .container .listType dd .txA .ctList .subj{
		width:18%;
		display:inline-block;
		vertical-align:top;
		font-size:15px;
		color:#004f86;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList{
		width:80%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList div{
		width:100%;
		font-size:15px;
		color:#444;
		margin-top:10px;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList div:first-child{
		margin-top:0px;
	}




}


@media screen and (max-width: 767px) {
	
	#sub .subCont{
		width:100%;
		margin:2em auto 2em;
	}
	
	#sub .subCont .container{
		width:100%;
		margin-top:4em;
	}
	
	#sub .subCont .container .lcOpLine{
		width:100%;
		display:inline-block;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu{
		display:flex;
		margin-top:0px;
		width:100%;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu li{
		display:inline-block;
		font-size:13px;
		padding:0px 0px;
		border-left:1px solid #e5e5e5;
		width:100%;
		text-align:center;
	}
	#sub .subCont .container .lcOpLine .lcD2_menu li a{
		color:#c1c1c1;
	}
	#sub .subCont .container .lcOpLine .lcD2_menu li:first-child{
		padding:0px 0px 0px 0px;
		border-left:0px solid #e5e5e5;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu .on a{
		color:#004f86;
		font-weight:bold;
	}
	
	#sub .subCont .container .lcOpLine .opA{
		display:inline-block;
		width:100%;
		clear:both;
		margin-top:1.2em;
	}
	
	
	#sub .subCont .container .lcOpLine .opA .alignList{
		display:inline-block;
		vertical-align:middle;
		margin-top:6px;
	}
	
	#sub .subCont .container .lcOpLine .opA .alignList li{
		display:inline-block;
		margin-left:0px;
		margin-right:10px;
	}
	
	
	#sub .subCont .container .lcOpLine .opA .listType{
		display:inline-block;
		vertical-align:middle;
		margin-left:0px;
		float:right;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType li{
		width:30px;
		display:inline-block;
		border:1px solid #444;
		box-sizing:border-box;
		float:left;
		opacity:0.2;
		margin-left:-1px;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType li img{
		width:100%;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType .on{
		opacity:1;
		
	}
	
	#sub .subCont .container .listType{
		width:100%;
		margin-top:15px;
	}
	
	#sub .subCont .container .listType dd{
		width:100%;
		padding:2em 0px;
		border-bottom:1px solid #e5e5e5;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .listType dd:first-child{
		border-top:1px solid #222;
	}
	
	
	
	#sub .subCont .container .listType dd .thum{
		width:30%;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .listType dd .thum img{
		width:100%;
	}
	
	
	#sub .subCont .container .listType dd .txA{
		width:69%;
		display:inline-block;
		vertical-align:middle;
		padding:0px 1.5em;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .listType dd .txA .subject{
		width:100%;
		font-size:16px;
		color:#222;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	#sub .subCont .container .listType dd .txA .subject span{
		font-weight:bold;
	}
	
	#sub .subCont .container .listType dd .txA .price{
		width:100%;
		font-size:18px;
		font-weight:bold;
		font-family:'lato','nanumsquare';
		color:#d30000;
		margin-top:10px;
	}
	
	
	#sub .subCont .container .listType dd .txA .price .won{
		font-size:13px;
		font-weight:normal;
	}
	
	
	#sub .subCont .container .listType dd .txA .price .pr{
		font-size:13px;
		font-weight:normal;
		color:#777;
		display:inline-block;
		margin-left:10px;
	}
	
	
	#sub .subCont .container .listType dd .txA .ctSubs{
		font-size:13px;
		color:#444;
		margin-top:1.5em;
		word-break:keep-all;
		line-height:21px;
	}
	
	
	
	#sub .subCont .container .listType dd .txA .ctList{
		margin-top:2em;
		width:100%;
	}
	
	
	#sub .subCont .container .listType dd .txA .ctList .subj{
		width:20%;
		display:inline-block;
		vertical-align:top;
		font-size:15px;
		color:#004f86;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList{
		width:78%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList div{
		width:100%;
		font-size:15px;
		color:#444;
		margin-top:10px;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList div:first-child{
		margin-top:0px;
	}

}


@media screen and (max-width: 450px) {
	
	#sub .subCont{
		width:100%;
		margin:2em auto 2em;
	}
	
	#sub .subCont .container{
		width:100%;
		margin-top:4em;
	}
	
	#sub .subCont .container .lcOpLine{
		width:100%;
		display:inline-block;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu{
		display:flex;
		margin-top:0px;
		width:100%;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu li{
		display:inline-block;
		font-size:13px;
		padding:0px 0px;
		border-left:1px solid #e5e5e5;
		width:100%;
		text-align:center;
	}
	#sub .subCont .container .lcOpLine .lcD2_menu li a{
		color:#c1c1c1;
	}
	#sub .subCont .container .lcOpLine .lcD2_menu li:first-child{
		padding:0px 0px 0px 0px;
		border-left:0px solid #e5e5e5;
	}
	
	#sub .subCont .container .lcOpLine .lcD2_menu .on a{
		color:#004f86;
		font-weight:bold;
	}
	
	#sub .subCont .container .lcOpLine .opA{
		display:inline-block;
		width:100%;
		clear:both;
		margin-top:1.2em;
	}
	
	
	#sub .subCont .container .lcOpLine .opA .alignList{
		display:inline-block;
		vertical-align:middle;
		margin-top:6px;
	}
	
	#sub .subCont .container .lcOpLine .opA .alignList li{
		display:inline-block;
		margin-left:0px;
		margin-right:10px;
	}
	
	
	#sub .subCont .container .lcOpLine .opA .listType{
		display:inline-block;
		vertical-align:middle;
		margin-left:0px;
		float:right;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType li{
		width:30px;
		display:inline-block;
		border:1px solid #444;
		box-sizing:border-box;
		float:left;
		opacity:0.2;
		margin-left:-1px;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType li img{
		width:100%;
	}
	
	#sub .subCont .container .lcOpLine .opA .listType .on{
		opacity:1;
		
	}
	
	#sub .subCont .container .listType{
		width:100%;
		margin-top:15px;
	}
	
	#sub .subCont .container .listType dd{
		width:100%;
		padding:2em 0px;
		border-bottom:1px solid #e5e5e5;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .listType dd:first-child{
		border-top:1px solid #222;
	}
	
	
	
	#sub .subCont .container .listType dd .thum{
		width:100%;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .listType dd .thum img{
		width:100%;
	}
	
	
	#sub .subCont .container .listType dd .txA{
		width:100%;
		display:inline-block;
		vertical-align:middle;
		padding:0px 0em;
		box-sizing:border-box;
		margin-top:1.5em;
	}
	
	#sub .subCont .container .listType dd .txA .subject{
		width:100%;
		font-size:16px;
		color:#222;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	#sub .subCont .container .listType dd .txA .subject span{
		font-weight:bold;
	}
	
	#sub .subCont .container .listType dd .txA .price{
		width:100%;
		font-size:18px;
		font-weight:bold;
		font-family:'lato','nanumsquare';
		color:#d30000;
		margin-top:10px;
	}
	
	
	#sub .subCont .container .listType dd .txA .price .won{
		font-size:13px;
		font-weight:normal;
	}
	
	
	#sub .subCont .container .listType dd .txA .price .pr{
		font-size:13px;
		font-weight:normal;
		color:#777;
		display:inline-block;
		margin-left:10px;
	}
	
	
	#sub .subCont .container .listType dd .txA .ctSubs{
		font-size:13px;
		color:#444;
		margin-top:1.5em;
		word-break:keep-all;
		line-height:21px;
	}
	
	
	
	#sub .subCont .container .listType dd .txA .ctList{
		margin-top:2em;
		width:100%;
	}
	
	
	#sub .subCont .container .listType dd .txA .ctList .subj{
		width:20%;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		color:#004f86;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList{
		width:78%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList div{
		width:100%;
		font-size:14px;
		color:#444;
		margin-top:10px;
	}
	
	#sub .subCont .container .listType dd .txA .ctList .subsList div:first-child{
		margin-top:0px;
	}


}






/*clarkPackageList02 - 패키지리스트02*/


@media screen and (max-width: 1180px) {

	#sub .subCont .container .blockType{
		width:100%;
		margin-top:15px;
		border-top:1px solid #222;
		border-bottom:1px solid #e5e5e5;
		padding:0px 0px 2.5em 0px;
		display:inline-block;
	}
	
	
	
	#sub .subCont .container .blockType dd{
		width:32%;
		display:inline-block;
		margin-left:2%;
		/* float:left; */
		margin-top:2.5em;
	}
	
	#sub .subCont .container .blockType dd:nth-child(3n+1){
		margin-left:0px;
	}
	
	
	#sub .subCont .container .blockType dd .thum{
		width:100%;
	}
	
	#sub .subCont .container .blockType dd .thum img{
		width:100%;
	}
	
	#sub .subCont .container .blockType dd .txA{
		width:100%;
		background:#f7f7f7;
		padding:20px;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .blockType dd .txA .subject{
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-size:16px;
		color:#222;
	}
	
	#sub .subCont .container .blockType dd .txA .subject span{
		font-weight:bold;
	}
	
	
	
	#sub .subCont .container .blockType dd .txA .price{
		width:100%;
		font-size:20px;
		font-weight:bold;
		font-family:'lato','nanumsquare';
		color:#d30000;
		margin-top:10px;
	}
	
	
	#sub .subCont .container .blockType dd .txA .price .won{
		font-size:16px;
		font-weight:normal;
	}
	
	
	#sub .subCont .container .blockType dd .txA .price .pr{
		font-size:14px;
		font-weight:normal;
		color:#777;
		display:block;
		margin-left:0px;
		margin-top:5px;
	}
	
	
	#sub .subCont .container .blockType dd .txA .ctSubs{
		font-size:14px;
		color:#444;
		margin-top:20px;
		word-break:keep-all;
		line-height:22px;
		height:85px;
		overflow:hidden;
	}


}


@media screen and (max-width: 767px) {

	#sub .subCont .container .blockType{
		width:100%;
		margin-top:15px;
		border-top:1px solid #222;
		border-bottom:1px solid #e5e5e5;
		padding:0px 0px 2.5em 0px;
		display:inline-block;
	}
	
	
	
	#sub .subCont .container .blockType dd{
		width:100%;
		display:inline-block;
		margin-left:0%;
		float:left;
		margin-top:2.5em;
	}
	
	#sub .subCont .container .blockType dd:nth-child(3n+1){
		margin-left:0px;
	}
	
	
	#sub .subCont .container .blockType dd .thum{
		width:100%;
	}
	
	#sub .subCont .container .blockType dd .thum img{
		width:100%;
	}
	
	#sub .subCont .container .blockType dd .txA{
		width:100%;
		background:#f7f7f7;
		padding:20px;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .blockType dd .txA .subject{
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-size:16px;
		color:#222;
	}
	
	#sub .subCont .container .blockType dd .txA .subject span{
		font-weight:bold;
	}
	
	
	
	#sub .subCont .container .blockType dd .txA .price{
		width:100%;
		font-size:20px;
		font-weight:bold;
		font-family:'lato','nanumsquare';
		color:#d30000;
		margin-top:10px;
	}
	
	
	#sub .subCont .container .blockType dd .txA .price .won{
		font-size:16px;
		font-weight:normal;
	}
	
	
	#sub .subCont .container .blockType dd .txA .price .pr{
		font-size:14px;
		font-weight:normal;
		color:#777;
		display:block;
		margin-left:0px;
		margin-top:5px;
	}
	
	
	#sub .subCont .container .blockType dd .txA .ctSubs{
		font-size:14px;
		color:#444;
		margin-top:20px;
		word-break:keep-all;
		line-height:22px;
		height:85px;
		overflow:hidden;
	}


}








/*golfgogo - 회사소개*/


@media screen and (max-width: 1280px) {
		
	#sub .golfgogo{
		width:100%;
		padding: 0px 0px 100px 0px;
	}
	
	#sub .golfgogo .product{
		width:100%;
		margin:4em auto 0px;
	}
	
	#sub .golfgogo .product .subSubj{
		font-size:20px;
		color:#222;
	}
	
	#sub .golfgogo .product .subj{
		font-size:26px;
		color:#222;
		font-weight:bold;
		margin-top:10px;
	}
	
	
	#sub .golfgogo .product .productList{
		width:100%;
		display:inline-block;
		margin-top:30px;
	}
	
	#sub .golfgogo .product .productList li{
		width:32%;
		display:Inline-block;
		float:left;
		margin-left:2%;
		position:relative;
	}
	
	#sub .golfgogo .product .productList li:first-child{
		margin-left:0px;
	}
	
	#sub .golfgogo .product .productList li .bg{
		width:100%;
	}
	
	#sub .golfgogo .product .productList li .bg img{
		width:100%;
	}
	
	#sub .golfgogo .product .productList li .tc{
		width:100%;
		position:absolute;
		bottom:0;
		padding:20px;
		box-sizing:border-box;
	}
	
	#sub .golfgogo .product .productList li .tc .enNm{
		font-size:14px;
		color:#c1c1c1;
	}
	
	#sub .golfgogo .product .productList li .tc .nm{
		font-size:20px;
		color:#fff;
		margin-top:20px;
		font-weight:bold;
	}
	
	#sub .golfgogo .product .productList li .tc .prnm{
		font-size:14px;
		color:#fff;
		margin-top:10px;
		word-break:keep-all;
		line-height:22px;
	}
	
	
	#sub .golfgogo .doc{
		width:100%;
		background:#f7f7f7;
		margin-top:4em;
		margin-bottom:100px;
		padding:4em 0px;
	}
	
	
	
	#sub .golfgogo .doc .inner{
		width:94%;
		margin:0 auto;
	}
	
	#sub .golfgogo .doc .inner .tit{
		font-size:26px;
		text-align:Center;
		font-weight:bold;
	}
	
	#sub .golfgogo .doc .inner .docList{
		width:100%;
		margin-top:30px;
	}
	
	
	#sub .golfgogo .doc .inner .docList li{
		margin:0px 10px;
	}
	
	
	#sub .golfgogo .doc .inner .docList .thum{
		width:100%;
	}
	
	#sub .golfgogo .doc .inner .docList .thum img{
		width:100%;
	}
	
	
	#sub .golfgogo .doc .inner .docList .subj{
		font-size:16px;
		margin-top:20px;
		text-align:Center;
	}
	
	
	
	#sub .golfgogo .doc .inner .docList .slick-prev{
		background:url(../images/sub/docPrev.png) no-repeat;
		width:13px;
		height:24px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#sub .golfgogo .doc .inner .docList .slick-next{
		background:url(../images/sub/docNext.png) no-repeat;
		width:13px;
		height:24px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}


}


@media screen and (max-width: 767px) {
		
	#sub .golfgogo{
		width:100%;
		padding: 0px 0px 50px 0px;

	}
	
	
	#sub .golfgogo .introduce{
		width:100%;
		position:relative;
		display:inline-block;
	}
	
	#sub .golfgogo .introduce .bg{
		width:55%;
		float:right;
		display:none;
	}
	
	#sub .golfgogo .introduce .bg img{
		width:100%;
	}
	
	
	
	#sub .golfgogo .introduce .cont{
		width:100%;
		position:relative;
		top:0%;
		transform:translateY(0%);
		left:0%;
		margin-left:0%;
	}
	
	
	#sub .golfgogo .introduce .cont .inner{
		width:100%;
		padding-right:1em;
		box-sizing:border-box;
	}
	
	#sub .golfgogo .introduce .cont .inner .enSubj{
		font-size:18px;
		color:#444;
		display:none;
	}
	
	#sub .golfgogo .introduce .cont .inner .nm{
		font-size:22px;
		color:#222;
		margin-top:1.5em;
	}
	
	#sub .golfgogo .introduce .cont .inner .nm span{
		font-weight:bold;
		display:inline-block;
	}
	
	
	#sub .golfgogo .introduce .cont .inner .subj{
		width:100%;
		margin-top:0.5em;
		font-size:18px;
		font-weight:bold;
		color:#222;
		line-height:26px;
		word-break:keep-all;
	}
	
	#sub .golfgogo .introduce .cont .inner .subj br:last-child{
		display:none;
	}
	
	#sub .golfgogo .introduce .cont .inner .subs{
		font-size:14px;
		color:#444;
		margin-top:1.5em;
		line-height:22px;
		word-break:keep-all;
	}
	
	
	#sub .golfgogo .product{
		width:100%;
		margin:4em auto 0px;
	}
	
	
	#sub .golfgogo .product .subSubj{
		font-size:20px;
		color:#222;
	}
	
	#sub .golfgogo .product .subj{
		font-size:26px;
		color:#222;
		font-weight:bold;
		margin-top:10px;
	}
	
	
	#sub .golfgogo .product .productList{
		width:100%;
		display:inline-block;
		margin-top:30px;
	}
	
	#sub .golfgogo .product .productList li{
		width:100%;
		display:Inline-block;
		float:left;
		margin-left:0%;
		position:relative;
		margin-top:20px;
	}
	
	#sub .golfgogo .product .productList li:first-child{
		margin-left:0px;
		margin-top:0px;
	}
	
	#sub .golfgogo .product .productList li .bg{
		width:100%;
	}
	
	#sub .golfgogo .product .productList li .bg img{
		width:100%;
	}
	
	#sub .golfgogo .product .productList li .tc{
		width:100%;
		position:absolute;
		bottom:0;
		padding:20px;
		box-sizing:border-box;
	}
	
	#sub .golfgogo .product .productList li .tc .enNm{
		font-size:14px;
		color:#c1c1c1;
	}
	
	#sub .golfgogo .product .productList li .tc .nm{
		font-size:20px;
		color:#fff;
		margin-top:20px;
		font-weight:bold;
	}
	
	#sub .golfgogo .product .productList li .tc .prnm{
		font-size:14px;
		color:#fff;
		margin-top:10px;
		word-break:keep-all;
		line-height:22px;
	}
	
	
	#sub .golfgogo .doc{
		width:100%;
		background:#f7f7f7;
		margin-top:4em;
		margin-bottom:4em;
		padding:4em 0px;
	}
	
	
	
	#sub .golfgogo .doc .inner{
		width:94%;
		margin:0 auto;
	}
	
	#sub .golfgogo .doc .inner .tit{
		font-size:20px;
		text-align:Center;
		font-weight:bold;
	}
	
	#sub .golfgogo .doc .inner .docList{
		width:100%;
		margin-top:30px;
	}
	
	
	#sub .golfgogo .doc .inner .docList li{
		margin:0px 10px;
	}
	
	
	#sub .golfgogo .doc .inner .docList .thum{
		width:100%;
	}
	
	#sub .golfgogo .doc .inner .docList .thum img{
		width:100%;
	}
	
	
	#sub .golfgogo .doc .inner .docList .subj{
		font-size:13px;
		margin-top:20px;
		text-align:Center;
	}
	
	
	
	#sub .golfgogo .doc .inner .docList .slick-prev{
		background:url(../images/sub/docPrev.png) no-repeat;
		width:13px;
		height:24px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#sub .golfgogo .doc .inner .docList .slick-next{
		background:url(../images/sub/docNext.png) no-repeat;
		width:13px;
		height:24px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}


}





/*miyazakiPackage - 마닐라,미야자키 패키지*/


@media screen and (max-width: 1180px) {
	
	
	#sub .subCont .container .packageView{
		width:100%;
		padding:50px 0px;
		border-top:1px solid #e5e5e5;
		border-bottom:1px solid #e5e5e5;
		margin-top:15px;
	}
	
	#sub .subCont .container .packageView .pvA{
		width:100%;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual{
		width:100%;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual li{
		margin:0px 10px;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual li img{
		width:100%;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual .slick-prev{
		background:url(../images/sub/pvVisualPrev.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#sub .subCont .container .packageView .pvA .pvVisual .slick-next{
		background:url(../images/sub/pvVisualNext.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo{
		width:100%;
		padding:40px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine{
		width:100%;
		display:inline-block;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA{
		display:inline-block;
		width:100%;
		vertical-align:top;
		padding-right:0px;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subj{
		width:100%;
		font-size:24px;
		font-weight:bold;
		color:#222;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subj .saleBat{
		width:70px;
		border:1px solid #004f86;
		color:#004f86;
		text-align:Center;
		padding:6px 0px;
		border-radius:50px;
		font-size:13px;
		display:inline-block;
		vertical-align:middle;
		margin-top:-5px;
		margin-right:10px;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subs{
		width:100%;
		word-break:keep-all;
		font-size:16px;
		color:#444;
		margin-top:10px;
		line-height:24px;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subs br{
		display:none;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA{
		display:inline-block;
		width:100%;
		vertical-align:top;
		text-align:left;
		margin-top:20px;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox{
		width:80%;
		display:inline-block;
		border:1px solid #e5e5e5;
		padding:20px;
		box-sizing:border-box;
		text-align:left;
		position:relative;
		cursor:pointer;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .tx{
		font-size:15px;
		color:#444;
		display:inline-block;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .ar{
		width:12px;
		display:Inline-block;
		float:right;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .sbDrop{
		position:absolute;
		margin-top:20px;
		border:1px solid #e5e5e5;
		width:100%;
		left:-1px;
		display:none;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .sbDrop li{
		padding:15px 20px;
		font-size:15px;
		background:#fff;
	}
	
	
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .bookBtn{
		display:Inline-block;
		padding:21px 30px;
		font-size:15px;
		color:#fff;
		background:#004f86;
		box-sizing:border-box;
		width:19%;
		text-align:Center;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .bookBtn a{
		color:#fff;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .price{
		width:100%;
		margin-top:50px;
		font-size:30px;
		color:#d30000;
		font-weight:bold;
		font-family:'lato','nanumsquare';
	}
	
	#sub .subCont .container .packageView .pvDeInfo .price span{
		font-size:16px;
		font-weight:normal;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .di{
		width:100%;
		margin-top:10px;
		font-size:16px;
		color:#444;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList{
		width:100%;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd{
		width:100%;
		padding:50px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .titA{
		width:17%;
		display:inline-block;
		vertical-align:top;
		font-size:24px;
		color:#222;
		font-weight:bold;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA{
		width:82%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo{
		width:100%;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li{
		width:100%;
		margin-top:30px;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li:first-child{
		margin-top:0px;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li .subj{
		width:15%;
		display:inline-block;
		vertical-align:top;
		color:#004f86;
		font-size:15px;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li .subs{
		width:83%;
		display:inline-block;
		vertical-align:top;
		color:#777777;
		font-size:15px;
		word-break:keep-all;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox{
		width:100%;
		border:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .subjLine{
		width:100%;
		border-bottom:1px solid #e5e5e5;
		background:#f7f7f7;
		padding:20px;
		font-size:18px;
		color:#222;
		font-weight:bold;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox{
		width:100%;
		padding:40px 20px;
		position:relative;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList{
		width:100%;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li{
		width:100%;
		margin-top:50px;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li:first-child{
		margin-top:0px;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li .day{
		width:60px;
		background:#fff;
		line-height:60px;
		color:#004f86;
		text-align:center;
		font-weight:bold;
		box-shadow:1px 1px 10px rgba(0,0,0,0.1);
		border-radius:100%;
		font-size:18px;
		display:inline-block;
		vertical-align:middle;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li .ct{
		width:80%;
		display:inline-block;
		vertical-align:middle;
		padding-left:20px;
		box-sizing:border-box;
		line-height:26px;
		font-size:15px;
		word-break:keep-all;
	}
	
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .line{
		width:1px;
		height:100%;
		background:#e5e5e5;
		position:absolute;
		margin-left:30px;
		z-index:-1;
		margin-top:0px;
		top:0;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .alrText{
		width:100%;
		text-align:right;
		margin-top:20px;
		font-size:16px;
		color:#777;
		line-height:26px;
	}
	
	
	#sub .subCont .container .packageView .anCClist{
		width:100%;
		padding:50px 0px;
		display:inline-block;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .anCClist dd{
		width:32%;
		display:inline-block;
		vertical-align:top;
		float:left;
		margin-left:2%;
	}
	
	#sub .subCont .container .packageView .anCClist dd:first-child{
		margin-left:0px;
	}
	
	#sub .subCont .container .packageView .anCClist dd .thum{
		width:100%;
	}
	
	#sub .subCont .container .packageView .anCClist dd .thum img{
		width:100%;
	}
	
	#sub .subCont .container .packageView .anCClist dd .subj{
		width:100%;
		font-size:18px;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-weight:bold;
		color:#222;
		margin-top:30px;
	}
	
	#sub .subCont .container .packageView .anCClist dd .enSubj{
		width:100%;
		font-size:14px;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		color:#777777;
		font-family:'lato';
		margin-top:5px;
	}
	
	#sub .subCont .container .packageView .anCClist dd .subs{
		font-size:14px;
		color:#444444;
		line-height:22px;
		margin-top:20px;
		height:68px;
		overflow:hidden;
		word-break:keep-all;
	}
	
	
	#sub .subCont .container .packageView .anCClist dd .diList{
		width:100%;
		margin-top:30px;
		padding-top:30px;
		border-top:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .anCClist dd .diList li{
		width:100%;
		margin-top:10px;
	}
	
	#sub .subCont .container .packageView .anCClist dd .diList li:first-child{
		margin-top:0px;
	}
	
	
	#sub .subCont .container .packageView .anCClist dd .diList .subj{
		width:14%;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		font-weight:normal;
		color:#004f86;
		margin:0;
		line-height:21px;
	}
	
	
	#sub .subCont .container .packageView .anCClist dd .diList .subs{
		width:83%;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		font-weight:normal;
		color:#444444;
		margin:0;
		height:auto;
		line-height:21px;
		overflow:Hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	#sub .subCont .container .packageView .hotelIn{
		width:100%;
		margin-top:80px;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual{
		width:48%;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li{
		width:100%;
		position:relative;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li .thum{
		width:100%;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li .thum img{
		width:100%;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li .nm{
		width:100%;
		position:absolute;
		bottom:0;
		padding:20px;
		box-sizing:border-box;
		background:rgba(0,0,0,0.5);
		color:#fff;
		font-size:18px;
	}
	
	
	
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual .slick-prev{
		background:url(../images/sub/hotelInPrev.png) no-repeat;
		width:40px;
		height:40px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:39px;
		top:-50px;
		z-index:2;
		cursor:pointer;
		background-size:100%;
	}
	
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual .slick-next{
		background:url(../images/sub/hotelInNext.png) no-repeat;
		width:40px;
		height:40px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:0px;
		top:-50px;
		z-index:2;
		cursor:pointer;
		background-size:100%;
	}
	
	
	
	#sub .subCont .container .packageView .hotelIn .inEx{
		width:48%;
		display:inline-block;
		vertical-align:middle;
		margin-left:3%;
	}
	
	#sub .subCont .container .packageView .hotelIn .inEx .subj{
		font-size:22px;
		color:#222;
		font-weight:bold;
	}
	
	#sub .subCont .container .packageView .hotelIn .inEx .enSubj{
		font-size:14px;
		color:#777;
		font-family:'lato';
		margin-top:5px;
	}
	
	#sub .subCont .container .packageView .hotelIn .inEx .subs{
		font-size:15px;
		color:#444444;
		margin-top:30px;
		line-height:23px;
		word-break:keep-all;
	}

}


@media screen and (max-width: 767px) {
	
	
	#sub .subCont .container .packageView{
		width:100%;
		padding:2.5em 0px;
		border-top:1px solid #e5e5e5;
		border-bottom:1px solid #e5e5e5;
		margin-top:15px;
	}
	
	#sub .subCont .container .packageView .pvA{
		width:100%;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual{
		width:100%;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual li{
		margin:0px 10px;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual li img{
		width:100%;
	}
	
	#sub .subCont .container .packageView .pvA .pvVisual .slick-prev{
		background:url(../images/sub/pvVisualPrev.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#sub .subCont .container .packageView .pvA .pvVisual .slick-next{
		background:url(../images/sub/pvVisualNext.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:-50px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo{
		width:100%;
		padding:40px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine{
		width:100%;
		display:inline-block;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA{
		display:inline-block;
		width:100%;
		vertical-align:top;
		padding-right:0px;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subj{
		width:100%;
		font-size:18px;
		font-weight:bold;
		color:#222;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subj .saleBat{
		width:60px;
		border:1px solid #004f86;
		color:#004f86;
		text-align:Center;
		padding:6px 0px;
		border-radius:50px;
		font-size:12px;
		display:inline-block;
		vertical-align:middle;
		margin-top:-5px;
		margin-right:10px;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subs{
		width:100%;
		word-break:keep-all;
		font-size:14px;
		color:#444;
		margin-top:10px;
		line-height:22px;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subs br{
		display:none;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA{
		display:inline-block;
		width:100%;
		vertical-align:top;
		text-align:left;
		margin-top:20px;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox{
		width:80%;
		display:inline-block;
		border:1px solid #e5e5e5;
		padding:1em;
		box-sizing:border-box;
		text-align:left;
		position:relative;
		cursor:pointer;
		vertical-align:middle;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .tx{
		font-size:14px;
		color:#444;
		display:inline-block;
		width:90%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .ar{
		width:12px;
		display:Inline-block;
		float:right;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .sbDrop{
		position:absolute;
		margin-top:20px;
		border:1px solid #e5e5e5;
		width:100%;
		left:-1px;
		display:none;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .sbDrop li{
		padding:15px 20px;
		font-size:15px;
		background:#fff;
	}
	
	
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .bookBtn{
		display:Inline-block;
		padding:1.5em 0px;
		font-size:13px;
		color:#fff;
		background:#004f86;
		box-sizing:border-box;
		width:18%;
		text-align:Center;
		vertical-align:middle;
	}
	
	#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .bookBtn a{
		color:#fff;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .price{
		width:100%;
		margin-top:50px;
		font-size:24px;
		color:#d30000;
		font-weight:bold;
		font-family:'lato','nanumsquare';
	}
	
	#sub .subCont .container .packageView .pvDeInfo .price span{
		font-size:14px;
		font-weight:normal;
	}
	
	
	#sub .subCont .container .packageView .pvDeInfo .di{
		width:100%;
		margin-top:10px;
		font-size:14px;
		color:#444;
		line-height:21px;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList{
		width:100%;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd{
		width:100%;
		padding:50px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .titA{
		width:100%;
		display:inline-block;
		vertical-align:top;
		font-size:20px;
		color:#222;
		font-weight:bold;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA{
		width:100%;
		display:inline-block;
		vertical-align:top;
		margin-top:20px;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo{
		width:100%;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li{
		width:100%;
		margin-top:30px;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li:first-child{
		margin-top:0px;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li .subj{
		width:100%;
		display:inline-block;
		vertical-align:top;
		color:#004f86;
		font-size:14px;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li .subs{
		width:100%;
		display:inline-block;
		vertical-align:top;
		color:#777777;
		font-size:14px;
		word-break:keep-all;
		margin-top:5px;
		line-height:21px;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox{
		width:100%;
		border:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .subjLine{
		width:100%;
		border-bottom:1px solid #e5e5e5;
		background:#f7f7f7;
		padding:20px;
		font-size:16px;
		color:#222;
		font-weight:bold;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox{
		width:100%;
		padding:40px 20px;
		position:relative;
		box-sizing:border-box;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList{
		width:100%;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li{
		width:100%;
		margin-top:50px;
	}
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li:first-child{
		margin-top:0px;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li .day{
		width:40px;
		background:#fff;
		line-height:40px;
		color:#004f86;
		text-align:center;
		font-weight:bold;
		box-shadow:1px 1px 10px rgba(0,0,0,0.1);
		border-radius:100%;
		font-size:16px;
		display:inline-block;
		vertical-align:middle;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li .ct{
		width:80%;
		display:inline-block;
		vertical-align:middle;
		padding-left:20px;
		box-sizing:border-box;
		line-height:21px;
		font-size:13px;
		word-break:keep-all;
	}
	
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .line{
		width:1px;
		height:100%;
		background:#e5e5e5;
		position:absolute;
		margin-left:20px;
		z-index:-1;
		margin-top:0px;
		top:0;
	}
	
	
	#sub .subCont .container .packageView .dtInfoList dd .contA .alrText{
		width:100%;
		text-align:right;
		margin-top:20px;
		font-size:13px;
		color:#777;
		line-height:21px;
	}
	
	
	#sub .subCont .container .packageView .anCClist{
		width:100%;
		padding:50px 0px;
		display:inline-block;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .anCClist dd{
		width:100%;
		display:inline-block;
		vertical-align:top;
		float:left;
		margin-left:0%;
		margin-top:2em;
	}
	
	#sub .subCont .container .packageView .anCClist dd:first-child{
		margin-left:0px;
		margin-top:0px;
	}
	
	#sub .subCont .container .packageView .anCClist dd .thum{
		width:100%;
	}
	
	#sub .subCont .container .packageView .anCClist dd .thum img{
		width:100%;
	}
	
	#sub .subCont .container .packageView .anCClist dd .subj{
		width:100%;
		font-size:18px;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-weight:bold;
		color:#222;
		margin-top:1.5em;
	}
	
	#sub .subCont .container .packageView .anCClist dd .enSubj{
		width:100%;
		font-size:14px;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		color:#777777;
		font-family:'lato';
		margin-top:5px;
	}
	
	#sub .subCont .container .packageView .anCClist dd .subs{
		font-size:14px;
		color:#444444;
		line-height:22px;
		margin-top:20px;
		height:68px;
		overflow:hidden;
		word-break:keep-all;
	}
	
	
	#sub .subCont .container .packageView .anCClist dd .diList{
		width:100%;
		margin-top:10px;
		padding-top:10px;
		border-top:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .packageView .anCClist dd .diList li{
		width:100%;
		margin-top:10px;
	}
	
	#sub .subCont .container .packageView .anCClist dd .diList li:first-child{
		margin-top:0px;
	}
	
	
	#sub .subCont .container .packageView .anCClist dd .diList .subj{
		width:14%;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		font-weight:normal;
		color:#004f86;
		margin:0;
		line-height:21px;
	}
	
	
	#sub .subCont .container .packageView .anCClist dd .diList .subs{
		width:83%;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		font-weight:normal;
		color:#444444;
		margin:0;
		height:auto;
		line-height:21px;
		overflow:Hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	#sub .subCont .container .packageView .hotelIn{
		width:100%;
		margin-top:80px;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual{
		width:100%;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li{
		width:100%;
		position:relative;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li .thum{
		width:100%;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li .thum img{
		width:100%;
	}
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual li .nm{
		width:100%;
		position:absolute;
		bottom:0;
		padding:20px;
		box-sizing:border-box;
		background:rgba(0,0,0,0.5);
		color:#fff;
		font-size:14px;
	}
	
	
	
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual .slick-prev{
		background:url(../images/sub/hotelInPrev.png) no-repeat;
		width:40px;
		height:40px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:39px;
		top:-50px;
		z-index:2;
		cursor:pointer;
		background-size:100%;
	}
	
	
	#sub .subCont .container .packageView .hotelIn .hotelInVisual .slick-next{
		background:url(../images/sub/hotelInNext.png) no-repeat;
		width:40px;
		height:40px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:0px;
		top:-50px;
		z-index:2;
		cursor:pointer;
		background-size:100%;
	}
	
	
	
	#sub .subCont .container .packageView .hotelIn .inEx{
		width:100%;
		display:inline-block;
		vertical-align:middle;
		margin-left:0%;
		margin-top:2em;
	}
	
	#sub .subCont .container .packageView .hotelIn .inEx .subj{
		font-size:18px;
		color:#222;
		font-weight:bold;
	}
	
	#sub .subCont .container .packageView .hotelIn .inEx .enSubj{
		font-size:14px;
		color:#777;
		font-family:'lato';
		margin-top:5px;
	}
	
	#sub .subCont .container .packageView .hotelIn .inEx .subs{
		font-size:14px;
		color:#444444;
		margin-top:30px;
		line-height:21px;
		word-break:keep-all;
	}

}













/*clarkGolf - 골프장정보*/


@media screen and (max-width: 1180px) {
	#sub .subCont .container .golfList{
		width:100%;
		margin-top:15px;
	}
	
	
	#sub .subCont .container .golfList dd{
		width:100%;
		padding:50px 0px 50px;
		box-sizing:border-box;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .golfList dd:first-child{
		border-top:1px solid #222;
	}
	
	
	#sub .subCont .container .golfList dd .visualA{
		width:32%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .golfList dd .visualA .num{
		font-size:30px;
		font-family:'lato';
		z-index:1;
		position:relative;
	}
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual{
		width:100%;
		margin-top:-15px;
		/* z-index: -1; */
	}
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual img{
		width:100%;
	}
	
	
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-prev{
		background:url(../images/sub/visualAPrev.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:0px;
		bottom:-50px;
		z-index:1;
		cursor:pointer;
	}
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-next{
		background:url(../images/sub/visualANext.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:0px;
		bottom:-50px;
		z-index:1;
		cursor:pointer;
	}
	
	#sub .subCont .container .golfList dd .visualA .count{
		text-align:center;
		margin-top:24px;
		font-size:16px;
		font-family:'lato';
	}
	
	
	
	
	
	#sub .subCont .container .golfList dd .ta{
		width:67%;
		display:inline-block;
		vertical-align:top;
		padding-left:50px;
		box-sizing:border-box;
		margin-top:15px;
	}
	
	
	#sub .subCont .container .golfList dd .ta .nm{
		font-size:20px;
		font-weight:bold;
		color:#222222;
		line-height:34px;
	}
	
	#sub .subCont .container .golfList dd .ta .inList{
		width:100%;
		margin-top:30px;
	}
	
	
	#sub .subCont .container .golfList dd .ta .inList li{
		width:100%;
		margin-top:15px;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subjArea{
		width:25%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subjArea .ico{
		width:21px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subjArea .subj{
		font-size:14px;
		color:#004f86;
		display:inline-block;
		vertical-align:middle;
		margin-left:6px;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subs{
		width:74%;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		color:#444;
		line-height:22px;
	}

}


@media screen and (max-width: 767px) {
	#sub .subCont .container .golfList{
		width:100%;
		margin-top:15px;
	}
	
	
	#sub .subCont .container .golfList dd{
		width:100%;
		padding:50px 0px 50px;
		box-sizing:border-box;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .golfList dd:first-child{
		border-top:1px solid #222;
	}
	
	
	#sub .subCont .container .golfList dd .visualA{
		width:100%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .golfList dd .visualA .num{
		font-size:24px;
		font-family:'lato';
		z-index:1;
		position:relative;
	}
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual{
		width:100%;
		margin-top:-15px;
	}
	
	/* #sub .subCont .container .golfList dd .visualA .golfLoVisual div.slick-slide{
		margin:0px 3px;
	} */
	/* #sub .subCont .container .golfList dd .visualA .golfLoVisual li{
		margin:0px 3px;
	} */
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual img{
		width:100%;
	}
	
	
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-prev{
		background:url(../images/sub/visualAPrev.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:0px;
		bottom:-50px;
		z-index:1;
		cursor:pointer;
	}
	
	#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-next{
		background:url(../images/sub/visualANext.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:0px;
		bottom:-50px;
		z-index:1;
		cursor:pointer;
	}

	#sub .golfLoVisual .slick-dots.slick-thumb {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	  
	#sub .golfLoVisual .slick-dots.slick-thumb li {
		width: 6px;
		height: 6px;
		margin: 0 3px;
		border-radius: 999px;
		transition: all 0.5s ease-in-out;
		will-change: width;
		background-color: black;
	}
	  
	#sub .golfLoVisual .slick-dots.slick-thumb li.slick-active {
		/* background-color: red; */
		background-color: #004f85;
		width: 24px;
	}
	
	#sub .subCont .container .golfList dd .visualA .count{
		text-align:center;
		margin-top:24px;
		font-size:16px;
		font-family:'lato';
	}
	
	
	
	
	
	#sub .subCont .container .golfList dd .ta{
		width:100%;
		display:inline-block;
		vertical-align:top;
		padding-left:0px;
		box-sizing:border-box;
		margin-top:80px;
	}
	
	
	#sub .subCont .container .golfList dd .ta .nm{
		font-size:20px;
		font-weight:bold;
		color:#222222;
		line-height:34px;
	}
	
	#sub .subCont .container .golfList dd .ta .inList{
		width:100%;
		margin-top:30px;
	}
	
	
	#sub .subCont .container .golfList dd .ta .inList li{
		width:100%;
		margin-top:15px;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subjArea{
		width:100%;
		display:inline-block;
		vertical-align:top;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subjArea .ico{
		width:21px;
		display:inline-block;
		vertical-align:middle;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subjArea .subj{
		font-size:14px;
		color:#004f86;
		display:inline-block;
		vertical-align:middle;
		margin-left:6px;
	}
	
	#sub .subCont .container .golfList dd .ta .inList li .subs{
		width:100%;
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		color:#444;
		line-height:22px;
		margin-top:10px;
	}

}






/*clarkEnjoy - 클락즐기기*/

@media screen and (max-width: 1180px) {
	#sub .subCont .container .enjoyList{
		width:100%;
		margin-top:15px;
	}
	
	#sub .subCont .container .enjoyList dd{
		width:100%;
		padding:50px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .enjoyList dd:first-child{
		border-top:1px solid #222;
	}
	
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisual{
		width:100%;
	}
	
	#sub .subCont .container .enjoyList dd .enjoyVisual li img{
		width:100%;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav{
		width:100%;
		margin-top:10px;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav li{
		margin:0px 5px;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav li img{
		width:100%;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav .slick-prev{
		background:url(../images/sub/visualAPrev.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:-16px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav .slick-next{
		background:url(../images/sub/visualANext.png) no-repeat;
		width:38px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:-16px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	
	
	
	
	
	#sub .subCont .container .enjoyList dd .ta{
		width:100%;
		margin-top:40px;
	}
	
	
	#sub .subCont .container .enjoyList dd .ta .subject{
		font-size:28px;
		color:#222;
		font-weight:bold;
		line-height:38px;
	}
	
	#sub .subCont .container .enjoyList dd .ta .subSubj{
		font-size:18px;
		color:#444444;
		margin-top:10px;
		line-height:28px;
	}
	
	
	#sub .subCont .container .enjoyList dd .ta .subs{
		font-size:16px;
		color:#444444;
		margin-top:30px;
		line-height:26px;
	}
	
}


@media screen and (max-width: 767px) {
	#sub .subCont .container .enjoyList{
		width:100%;
		margin-top:15px;
	}
	
	#sub .subCont .container .enjoyList dd{
		width:100%;
		padding:50px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .enjoyList dd:first-child{
		border-top:1px solid #222;
	}
	
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisual{
		width:100%;
	}
	
	#sub .subCont .container .enjoyList dd .enjoyVisual li img{
		width:100%;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav{
		width:100%;
		margin-top:10px;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav li{
		margin:0px 5px;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav li img{
		width:100%;
	}
	
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav .slick-prev{
		background:url(../images/sub/visualAPrev.png) no-repeat;
		width:30px;
		height:30px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:-10px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
		background-size:100%;
	}
	
	#sub .subCont .container .enjoyList dd .enjoyVisualNav .slick-next{
		background:url(../images/sub/visualANext.png) no-repeat;
		width:30px;
		height:30px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:-10px;
		top:50%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
		background-size:100%;
	}
	
	
	
	
	
	
	
	#sub .subCont .container .enjoyList dd .ta{
		width:100%;
		margin-top:40px;
	}
	
	
	#sub .subCont .container .enjoyList dd .ta .subject{
		font-size:20px;
		color:#222;
		font-weight:bold;
		line-height:30px;
	}
	
	#sub .subCont .container .enjoyList dd .ta .subSubj{
		font-size:15px;
		color:#444444;
		margin-top:10px;
		line-height:22px;
		word-break:keep-all;
	}
	
	
	#sub .subCont .container .enjoyList dd .ta .subs{
		font-size:14px;
		color:#444444;
		margin-top:30px;
		line-height:21px;
		word-break:keep-all;
	}
	
}











/*clarkHotel - 호텔*/


@media screen and (max-width: 1180px) {
	#sub .subCont .container .hotelList{
		width:100%;
		margin-top:15px;
	}
	#sub .subCont .container .hotelList dd{
		width:100%;
		padding:50px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .hotelList dd:first-child{
		border-top:1px solid #222;
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea{
		width:100%;
		display:inline-block;
		
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea .bThum{
		width:49%;
		display:Inline-block;
		float:left;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .bThum img{
		width:100%;
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList{
		width:49%;
		display:Inline-block;
		margin-left:2%;
		float:Left;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div{
		width:48%;
		display:inline-block;
		float:left;
		margin-left:4%;
		margin-top:20px;
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div img{
		width:100%;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(2n+1){
		margin-left:0px;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(1),
	#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(2){
		margin-top:0px;
	}
	
	
	#sub .subCont .container .hotelList dd:nth-child(2n) .thumArea .sThumList{
		margin-left:0px;
		margin-right:2%;
	}
	
	
	
	
	#sub .subCont .container .hotelList dd .ta{
		width:100%;
		margin-top:40px;
	}
	
	
	
	
	
	
	#sub .subCont .container .hotelList dd .ta .nmLine{
		width:100%;
	}
	
	#sub .subCont .container .hotelList dd .ta .nmLine .nm{
		font-size:28px;
		display:inline-block;
		vertical-align:bottom;
		color:#222;
		font-weight:bold;
	}
	#sub .subCont .container .hotelList dd .ta .nmLine .en{
		font-size:16px;
		display:inline-block;
		vertical-align:bottom;
		color:#777777;
		font-family:'lato';
		margin-left:10px;
	}
	
	#sub .subCont .container .hotelList dd .ta .po{
		font-size:18px;
		color:#444444;
		margin-top:10px;
		line-height:28px;
	}
	
	
	#sub .subCont .container .hotelList dd .ta .subs{
		font-size:16px;
		color:#444444;
		margin-top:30px;
		line-height:26px;
		word-break:keep-all;
	}

}

@media screen and (max-width: 767px) {
	#sub .subCont .container .hotelList{
		width:100%;
		margin-top:15px;
	}
	#sub .subCont .container .hotelList dd{
		width:100%;
		padding:50px 0px;
		border-bottom:1px solid #e5e5e5;
	}
	
	#sub .subCont .container .hotelList dd:first-child{
		border-top:1px solid #222;
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea{
		width:100%;
		display:inline-block;
		
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea .bThum{
		width:100%;
		display:Inline-block;
		float:left;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .bThum img{
		width:100%;
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList{
		width:100%;
		display:Inline-block;
		margin-left:0%;
		float:Left;
		margin-top:10px;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div{
		width:48%;
		display:inline-block;
		float:left;
		margin-left:4%;
		margin-top:10px;
	}
	
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div img{
		width:100%;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(2n+1){
		margin-left:0px;
	}
	
	#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(1),
	#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(2){
		margin-top:0px;
	}
	
	
	#sub .subCont .container .hotelList dd:nth-child(2n) .thumArea .sThumList{
		margin-left:0px;
		margin-right:0%;
		margin-bottom:10px;
	}
	
	
	
	
	#sub .subCont .container .hotelList dd .ta{
		width:100%;
		margin-top:40px;
	}
	
	
	
	
	
	
	#sub .subCont .container .hotelList dd .ta .nmLine{
		width:100%;
	}
	
	#sub .subCont .container .hotelList dd .ta .nmLine .nm{
		font-size:20px;
		display:block;
		vertical-align:bottom;
		color:#222;
		font-weight:bold;
	}
	#sub .subCont .container .hotelList dd .ta .nmLine .en{
		font-size:14px;
		display:block;
		vertical-align:bottom;
		color:#777777;
		font-family:'lato';
		margin-left:0px;
		margin-top:5px;
		word-break:keep-all;
	}
	
	#sub .subCont .container .hotelList dd .ta .po{
		font-size:16px;
		color:#444444;
		margin-top:15px;
		line-height:24px;
		word-break:keep-all;
	}
	
	
	#sub .subCont .container .hotelList dd .ta .subs{
		font-size:14px;
		color:#444444;
		margin-top:30px;
		line-height:22px;
		word-break:keep-all;
	}
	
	#sub .subCont .container .hotelList dd .ta .subs br{
		display:none;
	}

	#sub .page-info .page-info-text p{
		font-size: 40px;
		font-weight: bold;
	}
}
















