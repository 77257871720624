#main{
	width:100%;
}

#main .visual{
	width:100%;
	position:relative;
}

#main .visual .mainVisual{
	width:100%;
}

#main .visual .mainVisual li{
	width:100%;
	position:relative;
}

#main .visual .mainVisual li .bg{
	width:100%;
}

#main .visual .mainVisual li .bg .img-bg-opacity{
	position: absolute;
	top: 0%;
	left: 0%;
	width:100%;
	height: 100%;
	background-color: black;
	opacity: 0.5;
}

#main .visual .mainVisual li .bg img{
	width:100%;
}

#main .visual .mainVisual li .cont{
	width:100%;
	position:absolute;
	padding:0px 100px;
	transform:translateY(150%);
}

#main .visual .mainVisual li .cont .title{
	font-size:80px;
	color:#fff;
	font-weighT:bold;
	font-family:'lato','nanumsquare';
}


#main .visual .mainVisual li .bg .slide-substance{
	position: absolute;
	top: 50%;
	left: 5%;
	font-size:48px;
	color: white;
	transform: translate(0,-50%);
	font-weight: 200;
}

#main .visual .mainVisual li .bg .slide-substance .dream-span{
	font-weight: 500;
}

#main .visual .mainVisual li .bg .slide-substance .dream-span-two{
	font-weight: 500;
	font-size: 54px;
}

#main .visual .mainVisual li .bg .slide-substance .text-dot{
	text-emphasis: filled white;
	text-emphasis-position: revert;
}

.font-bold{
	font-weight: 800;
}




#main .visual .mainVisual li .cont .substance{
	position: relative;
  	width: fit-content;
	top: 50%;
	/* left: 50%; */
  	text-align: left;
	/* transform: translate(-50%, -50%); */
	font-size:48px;
	color: white;
	font-weight:200;
  	background-color: rgba(255, 255, 255, 0);
}


#main .visual .mainVisual .slick-prev{
	background:url(../images/mainVisual_prev.png) no-repeat;
	width:27px;
	height:53px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:50px;
	top:50%;
	z-index:2;
	cursor:pointer;
	margin: -26px 0 0 -26px;
}


#main .visual .mainVisual .slick-next{
	background:url(../images/mainVisual_next.png) no-repeat;
	width:27px;
	height:53px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:45px;
	top:50%;
	cursor:pointer;
	margin: -26px 0 0 -26px;
}


#main .mainTitle{
	width:100%;
	text-align:Center;
	font-size:36px;
	color:#222;
	font-weight: bold;
	margin-bottom: 50px;
	
}





#main .golf{
	width:100%;
	margin-top:100px;
	text-align:Center;
	position:relative;
}

#main .golf .golfTab{
	width:100%;
	margin-top:50px;
	text-align:Center;
}


#main .golf .golfTab li{
	width:130px;
	display:inline-block;
	background:#f7f7f7;
	text-align:center;
	padding:15px 0px;
	font-size:18px;
	color:#222;
	border-radius:50px;
	cursor:pointer;
}


#main .golf .more{
	text-align:Center;
	font-size:18px;
	color:#004f86;
	padding:0px 5px 2px;
	border-bottom:1px solid #004f86;
	display:inline-block;
	margin-top:50px;
}

#main .golf .more a{
	color:#004f86;
}

#main .golf .more span{
	margin-left:5px;
}

#main .golf .golfTitles {
	cursor: pointer;
	background-color: #f7f7f7;
	color: #222;
}
#main .golf .golfTitles.on {
	background-color: #004f86;
	color: #fff;
}

#main .golf .golfCont{
	position:absolute;	
	widtH:100%;
	bottom:0;
	margin-top:50px;
	display: none;	
}	
#main .golf .golfCont.on {	
	position: relative;
	display: block;	
	animation-duration: 1s;
  animation-name: fadeIn;
}	

@keyframes fadeIn {
  from {		
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#main .golf .golfCont .slick-list .slick-track .slick-slide li .thum{
	border-radius: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	aspect-ratio: 1 / 1;
}

/* .slick-slide > div {
	display: flex;
	justify-content: center;
}
.slick-slide > div > li{
	display: flex !important;
	justify-content: center;
} */

#main .golf .golfVisual{
	width:100%;
	position:relative;
}

#main .golf .golfVisual .slick-dots{
	width:100%;
	position:absolute;
	margin-top:30px;
	text-align:center;
}

#main .golf .golfVisual .slick-dots li{
	width:8px;
	height:8px;
	margin:0 3px;
	outline:0;
	text-indent:-9999px;
	background:#fff;
	border:1px solid #004f86;
	border-radius:10px;
	display:inline-block;
	vertical-align:middle;
}

#main .golf .golfVisual .slick-dots li:hover,
#main .golf .golfVisual .slick-dots li.slick-active{
	width:10px;
	height:10px;
	border:2px solid #004f86;
}



#main .golf .golfVisual .slick-prev{
	background:url(../images/golfVisual_prev.png) no-repeat;
	width:20px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:50%;
	margin-left:-270px;
	top:35%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}


#main .golf .golfVisual .slick-next{
	background:url(../images/golfVisual_Next.png) no-repeat;
	width:20px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:50%;
	margin-right:-270px;
	transform:translateY(-50%);
	top:35%;
	cursor:pointer;
}


#main .golf .golfVisual li{
	/* margin:0px 15px; */
}


#main .golf .golfVisual li .thum{
	width:100%;
	filter:grayscale(1);
	opacity:0.5;
	transform:scale(0.8);
}


#main .golf .golfVisual .thum img{
	width:100%;
	object-fit: cover;
	object-position: center center;
}

#main .golf .golfVisual li .textCont{
	width:100%;
	margin-top:40px;
	display:none;
}

#main .golf .golfVisual li .textCont .po{
	font-size:16px;
	color:#777777;
	text-align:Center;
	font-family:'lato','nanumsquare';
}

#main .golf .golfVisual li .textCont .subj{
	font-size:20px;
	color:#222;
	text-align:Center;
	font-family:'lato','nanumsquare';
	font-weight:bold;
	margin-top:10px;
}


#main .golf .golfVisual li .textCont .subs{
	font-size:18px;
	color:#444;
	text-align:Center;
	margin-top:30px;
	line-height:26px;
	word-break:keep-all;
}


#main .golf .golfVisual li .textCont .arl{
	font-size:16px;
	color:#004f86;
	text-align:Center;
	margin-top:15px;
	line-height:24px;
}



#main .golf .golfVisual .slick-center .thum{
	width:100%;
	filter:none;
	opacity:1;
	transform:scale(1);	
}


#main .golf .golfVisual .slick-center .textCont{
	display:block;
}



#main .package{
	width:100%;
	background:#f7f7f7;
	padding:10px 0px 50px 0px;
	margin-top:0px;
	padding: 150px 0px;
}

#main .package .inner{
	max-width:1180px;
	margin:0 auto;
}

#main .package .inner .packageList{
	width:100%;
	display:flex;
}

#main .package .inner .packageList li{
	width:100%;
	display:inline-block;
	margin-left:20px;
}

#main .package .inner .packageList li:first-child{
	margin-left:0px;
}

#main .package .inner .packageList li .thum{
	width:100%;
}

#main .package .inner .packageList li .thum img{
	width:100%;
}

#main .package .inner .packageList li .bat{
	width:60px;
	position:relative;
	margin-top:-12px;
	background:#004f86;
	color:#fff;
	text-align:center;
	margin-left:20px;
	padding:5px 0px;
	font-family:'lato';
	font-size:13px;
	z-index:1;
}

#main .package .inner .packageList li .textArea{
	width:100%;
	background:#fff;
	padding:30px 20px;
	box-sizing:border-box;
	margin-top:-12px;
}

#main .package .inner .packageList li .textArea .subj{
	font-size:18px;
	color:#222;
	font-weight:bold;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}

#main .package .inner .packageList li .textArea .subs{
	font-size:15px;
	color:#777777;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	margin-top:8px;
}

#main .package .inner .packageList li .textArea .price{
	font-size:18px;
	color:#d30000;
	width:100%;
	margin-top:30px;
	font-family:'lato','nanumsquare';
}

#main .package .inner .packageList li:hover{
  transform: scale(1.1);
}

#main .promotion{
  height: 240px;
  width:100%;
  padding: 100px 0px;
	margin:0px;
  background-image: url("/public/assets/images/main/members.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
}

#main .promotion .thum{
	width:100%;
}

#main .promotion .thum img{
	width:100%;
}

#main .promotion .textArea{
  /* background-color: #004f86; */
	width:100%;
	position:relative;
  bottom: 25px;
  text-align:right;
  margin: 0px 0px 400px 0px;
}

#main .promotion .textArea .subs{
  width:80%;
	font-size:24px;
	color:#000;
	opacity:0.9;
  text-shadow: 2px 2px 2px gray;
}

#main .promotion .textArea .subj{
  width:80%;
	font-size:48px;
	color:#091856;
	font-weight:bold;
	margin-top:0px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 2px gray;
}

#main .promotion .textArea .btn{
	font-size:24px;
  padding: 10px;
	color:#fff;
	font-weight:300;
	margin-top:0px;
  background-color: rgba(0,0,0,0);
  border: 1px solid #777777;
  border-radius: 5px;
  cursor: pointer;
}

#main .promotion .textArea .btn:hover{
  background-color: #777777;
  opacity: 0.5;
}







#main .news{
	max-width:1180px;
	margin:100px auto;
}

#main .news .titleLine{
	width:100%;
	display:inline-block;
}

#main .news .titleLine .titleArea{
	display:inline-block;
}

#main .news .titleLine .titleArea .en{
	font-size:20px;
	color:#777;
}

#main .news .titleLine .titleArea .title{
	font-size:36px;
	margin-top:10px;
	color:#222222;
	font-weight:bold;
}

#main .news .titleLine .more{
	display:inline-block;
	float:right;
	margin-top:45px;
}

#main .news .titleLine .more .text{
	display:inline-block;
	vertical-align:middle;
	font-size:18px;
	color:#004f86
}

#main .news .titleLine .more .ico{
	width:28px;
	display:Inline-block;
	vertical-align:middle;
}

#main .news .titleLine .more .ico img{
	width:auto;
}


#main .news .newsCont{
	width:100%;
	display:inline-block;
	margin-top:30px;
}

#main .news .newsCont .notice{
	width:49%;
	display:inline-block;
	float:left;
	position:relative;
}

#main .news .newsCont .notice .bg{
	width:100%;
}



#main .news .newsCont .notice .bg img{
	width:100%;
}

#main .news .newsCont .cont{
	width:60%;
	position:absolute;
	padding:30px;
	box-sizing:border-box;
	left:0;
	bottom:0;
	background:#fff;
}

#main .news .newsCont .cont .bat{
	width:50px;
	background:#004f86;
	color:#fff;
	text-align:center;
	padding:6px 0px;
	font-size:13px;
	border-radius:50px;
}

#main .news .newsCont .cont .subject{
	font-size:18px;
	color:#222;
	margin-top:10px;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	font-weight:bold;
}

#main .news .newsCont .cont .substance{
	font-size:15px;
	color:#444;
	margin-top:15px;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}


#main .news .newsCont .cont .date{
	font-size:15px;
	color:#999;
	margin-top:30px;
	font-family:'lato'
}

#main .news .newsCont .newsList{
	width:49%;
	display:inline-block;
	margin-left:2%;
}

#main .news .newsCont .newsList li{
	width:48.5%;
	display:inline-block;
	margin-left:3%;
	float:left;
	border:1px solid #e5e5e5;
	box-sizing:border-box;
	margin-top:20px;
	padding:30px;
}

#main .news .newsCont .newsList li:nth-child(2n+1){
	margin-left:0%;
}

#main .news .newsCont .newsList li:nth-child(2),
#main .news .newsCont .newsList li:nth-child(3){
	background:#f7f7f7;
}


#main .news .newsCont .newsList li:nth-child(1),
#main .news .newsCont .newsList li:nth-child(2){
	margin-top:0;
}


#main .news .newsCont .newsList li .subject{
	font-size:18px;
	width:100%;
	color:#222;
	line-height:28px;
	height:56px;
	overflow:Hidden;
	word-break:keep-all;
}

#main .news .newsCont .newsList li .date{
	font-size:15px;
	width:100%;
	color:#777;
	margin-top:54px;
}

.kakaoChat{
	position: fixed;
	right: 0%;
	bottom: 0%;
}

.kakaoChat > a > img{
	width: 70%;
	/*  border-radius: 30px;
	box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px; */
}



































































