#sub .golfgogo .donation{
	width:100%;
	display:inline-block;
	text-align: center;
}

#sub .golfgogo .donation table,th,td{
	border: 1px solid white;
	padding: 15px 30px;
}

#sub .golfgogo .donation th{
	color: white;
	background-color: #0075c1;
}

#sub .golfgogo .donation td{
	background-color: #f3f3f3;
	text-align: center;
}

#sub .golfgogo .donation .last-tr td{
  background-color: #d3d3d3;
}

#sub .golfgogo .donation .last-tr td:last-child{
  text-align: center;
  background-color: #d3d3d3;
}


@media screen and (max-width: 767px){
	#sub .golfgogo .donation table,th,td{
		border: 1px solid white;
		padding: 10px 0px;
	}

	#sub .golfgogo .donation th{
		font-size: 10px;
	}

	#sub .golfgogo .donation td{
		font-size: 10px;
	}
}