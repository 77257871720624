@media screen and (max-width:1180px) {
	#main{
		width:100%;
	}
	
	#main .visual{
		width:100%;
		position:relative;
	}

	#main .visual .mainVisual{
		width:100%;
	}
	
	#main .visual .mainVisual li{
		width:100%;
		position:relative;
	}
	
	#main .visual .mainVisual li .bg{
		width:100%;
	}
	
	#main .visual .mainVisual li .bg img{
		width:100%;
		height: 500px;
	}
	
	#main .visual .mainVisual li .cont{
		width:100%;
		position:absolute;
		padding:0px 2.5em;
		top:50%;
		transform:translateY(-50%);
	}
	
	#main .visual .mainVisual li .cont .title{
		font-size:60px;
		color:#000;
		font-weighT:bold;
		font-family:'lato','nanumsquare';
	}

	#main .visual .mainVisual li .bg .slide-substance{
		font-size:36px;
	}

	#main .visual .mainVisual li .bg .slide-substance .dream-span-two{
		font-size: 48px;
	}

	
	
	#main .visual .mainVisual li .cont .substance{
		font-size:30px;
		color:#000;
		margin-top:20px;
		font-weight:300;
	}
	
	#main .visual .mainVisual li .cont .substance span{
		font-weight:bold;
	}
	
	
	
	#main .visual .mainVisual .slick-prev{
		background:url(../images/mainVisual_prev.png) no-repeat;
		width:20px;
		height:53px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:50px;
		top:50%;
		z-index:2;
		cursor:pointer;
		background-size:100%;
	}
	
	
	#main .visual .mainVisual .slick-next{
		background:url(../images/mainVisual_next.png) no-repeat;
		width:20px;
		height:53px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:22px;
		top:50%;
		cursor:pointer;
		background-size:100%;
	}
	
	
	#main .mainTitle{
		width:100%;
		text-align:Center;
		font-size:36px;
		color:#222;
		font-weight:bold;
	}
	
	
	
	
	
	#main .golf{
		width:100%;
		margin-top:100px;
		text-align:Center;
		position:relative;
	}
	
	#main .golf .golfTab{
		width:100%;
		margin-top:50px;
		text-align:Center;
	}
	
	
	#main .golf .golfTab li{
		width:130px;
		display:inline-block;
		background:#f7f7f7;
		text-align:center;
		padding:15px 0px;
		font-size:18px;
		color:#222;
		border-radius:50px;
		cursor:pointer;
	}
	
	
	#main .golf .more{
		text-align:Center;
		font-size:18px;
		color:#004f86;
		padding:0px 5px 2px;
		border-bottom:1px solid #004f86;
		display:inline-block;
		margin-top:50px;
	}
	
	
	#main .golf .more a{
		color:#004f86;
	}
	
	#main .golf .more span{
		margin-left:5px;
	}

	#main .golf .golfTitles {
		cursor: pointer;
		background-color: #f7f7f7;
		color: #222;
	}
	#main .golf .golfTitles.on {
		background-color: #004f86;
		color: #fff;
	}
	
	#main .golf .golfCont{
		position:absolute;
		widtH:100%;
		bottom:0;
		margin-top:50px;
		display: none;		
	}	

	#main .golf .golfCont.on {	
		position: relative;
		display: block;		
	}	
	
	#main .golf .golfVisual{
		width:100%;
		position:relative;
	}



	#main .golf .golfVisual .slick-prev{
		background:url(../images/golfVisual_prev.png) no-repeat;
		width:20px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:50%;
		margin-left:-270px;
		top:35%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#main .golf .golfVisual .slick-next{
		background:url(../images/golfVisual_Next.png) no-repeat;
		width:20px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:50%;
		margin-right:-270px;
		transform:translateY(-50%);
		top:35%;
		cursor:pointer;
	}


	#main .golf .golfVisual li{
		/* margin:0px 15px; */
	}
	
	
	#main .golf .golfVisual li .thum{
		width:100%;
		filter:grayscale(1);
		opacity:0.5;
		transform:scale(0.8);
	}
	
	
	#main .golf .golfVisual .thum img{
		width:100%;
		object-fit: cover;
		object-position: center center;
	}
	
	#main .golf .golfVisual li .textCont{
		width:100%;
		margin-top:40px;
		display:none;
	}
	
	#main .golf .golfVisual li .textCont .po{
		font-size:16px;
		color:#777777;
		text-align:Center;
		font-family:'lato','nanumsquare';
	}
	
	#main .golf .golfVisual li .textCont .subj{
		font-size:20px;
		color:#222;
		text-align:Center;
		font-family:'lato','nanumsquare';
		font-weight:bold;
		margin-top:10px;
	}
	
	
	#main .golf .golfVisual li .textCont .subs{
		font-size:18px;
		color:#444;
		text-align:Center;
		margin-top:30px;
		line-height:26px;
		word-break:keep-all;
	}
	
	
	#main .golf .golfVisual li .textCont .arl{
		font-size:16px;
		color:#004f86;
		text-align:Center;
		margin-top:15px;
		line-height:24px;
	}
	
	
	
	#main .golf .golfVisual .slick-center .thum{
		width:100%;
		filter:none;
		opacity:1;
		transform:scale(1);
	}
	
	
	#main .golf .golfVisual .slick-center .textCont{
		display:block;
	}
	
	
	
	


	#main .package{
		width:100%;
		background:#f7f7f7;
		padding:100px 0px;
	}
	
	#main .package .inner{
		width:94%;
		margin:0 auto;
	}
	
	
	#main .package .inner .packageList{
		width:100%;
		margin-top:2.5em;
		display:inline-block;
	}
	
	#main .package .inner .packageList li{
		width:24%;
		display:inline-block;
		margin-left:1.3%;
		float:left;
	}
	
	#main .package .inner .packageList li:first-child{
		margin-left:0px;
	}
	
	#main .package .inner .packageList li .thum{
		width:100%;
	}
	
	#main .package .inner .packageList li .thum img{
		width:100%;
	}
	
	#main .package .inner .packageList li .bat{
		width:60px;
		position:relative;
		margin-top:-12px;
		background:#004f86;
		color:#fff;
		text-align:center;
		margin-left:20px;
		padding:5px 0px;
		font-family:'lato';
		font-size:13px;
		z-index:1;
	}
	
	#main .package .inner .packageList li .textArea{
		width:100%;
		background:#fff;
		padding:30px 20px;
		box-sizing:border-box;
		margin-top:-12px;
	}
	
	#main .package .inner .packageList li .textArea .subj{
		font-size:18px;
		color:#222;
		font-weight:bold;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	#main .package .inner .packageList li .textArea .subs{
		font-size:15px;
		color:#777777;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		margin-top:8px;
	}
	
	#main .package .inner .packageList li .textArea .price{
		font-size:18px;
		color:#d30000;
		width:100%;
		margin-top:30px;
		font-family:'lato','nanumsquare';
	}
	
		
	
	
	#main .promotion{
		width:94%;
		margin:5em auto 0;
	}
	
	#main .promotion .promotionList{
		width:100%;
		display:inline-block;
		position:relative;
		margin-top:50px;
	}
	
	
	#main .promotion .promotionList dd .thum{
		width:100%;
	}
	
	#main .promotion .promotionList dd .thum img{
		width:100%;
	}
	
	#main .promotion .promotionList dd .textArea{
		width:100%;
		position:absolute;
		right:0px;
		bottom:0px;
		text-align:right;
		padding:1em;
		box-sizing:border-box;
	}
	
	#main .promotion .promotionList dd .textArea .subs{
		font-size:14px;
		color:#fff;
		opacity:0.8;
	}
	
	#main .promotion .promotionList dd .textArea .subj{
		font-size:18px;
		color:#fff;
		font-weight:bold;
		margin-top:10px;
	}
	
	#main .promotion .promotionList dd .textArea .more{
		font-size:14px;
		color:#fff;
		margin-top:20px;
	}
	
	#main .promotion .promotionList dd .textArea .more a{
		color:#fff;
	}
	
	#main .promotion .promotionList dd .textArea .moreList{
		width:100%;
		text-align:right;
		margin-top:20px;
	}
	
	#main .promotion .promotionList dd .textArea .moreList li{
		font-size:14px;
		color:#fff;
		display:inline-block;
		padding:0px 10px;
		border-left:1px solid #fff;
		line-height:16px;
	}
	
	
	#main .promotion .promotionList dd .textArea .moreList li:first-child{
		border-left:0px solid #fff;
	}
	
	#main .promotion .promotionList dd .textArea .moreList li:last-child{
		padding:0px 0px 0px 10px;
	}
	
	
	#main .promotion .promotionList dd .textArea .moreList li a{
		color:#fff;
	}




	
	
	
	
	
	#main .news{
		width:94%;
		margin:100px auto;
	}
	
	#main .news .titleLine{
		width:100%;
		display:inline-block;
	}
	
	#main .news .titleLine .titleArea{
		display:inline-block;
	}
	
	#main .news .titleLine .titleArea .en{
		font-size:20px;
		color:#777;
	}
	
	#main .news .titleLine .titleArea .title{
		font-size:36px;
		margin-top:10px;
		color:#222222;
		font-weight:bold;
	}
	
	#main .news .titleLine .more{
		display:inline-block;
		float:right;
		margin-top:45px;
	}
	
	#main .news .titleLine .more .text{
		display:inline-block;
		vertical-align:middle;
		font-size:18px;
		color:#004f86
	}
	
	#main .news .titleLine .more .ico{
		width:28px;
		display:Inline-block;
		vertical-align:middle;
	}
	
	#main .news .titleLine .more .ico img{
		width:100%;
	}
	
	
	#main .news .newsCont{
		width:100%;
		display:flex;
		margin-top:30px;
	}
	
	#main .news .newsCont .notice{
		width:49%;
		display:inline-block;
		float:left;
		position:relative;
	}
	
	#main .news .newsCont .notice .bg{
		width:100%;
		height:100%;
		overflow:hidden;
	}
	
	#main .news .newsCont .notice .bg img{
		height:100%;
	}
	
	#main .news .newsCont .cont{
		width:60%;
		position:absolute;
		padding:30px;
		box-sizing:border-box;
		left:0;
		bottom:0;
		background:#fff;
	}
	
	#main .news .newsCont .cont .bat{
		width:50px;
		background:#004f86;
		color:#fff;
		text-align:center;
		padding:6px 0px;
		font-size:13px;
		border-radius:50px;
	}
	
	#main .news .newsCont .cont .subject{
		font-size:18px;
		color:#222;
		margin-top:10px;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-weight:bold;
	}
	
	#main .news .newsCont .cont .substance{
		font-size:15px;
		color:#444;
		margin-top:15px;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	
	#main .news .newsCont .cont .date{
		font-size:15px;
		color:#999;
		margin-top:30px;
		font-family:'lato'
	}
	
	#main .news .newsCont .newsList{
		width:49%;
		display:inline-block;
		margin-left:2%;
	}
	
	#main .news .newsCont .newsList li{
		width:48.5%;
		display:inline-block;
		margin-left:3%;
		float:left;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
		margin-top:15px;
		padding:1.5em;
	}
	
	#main .news .newsCont .newsList li:nth-child(2n+1){
		margin-left:0%;
	}
	
	#main .news .newsCont .newsList li:nth-child(2),
	#main .news .newsCont .newsList li:nth-child(3){
		background:#f7f7f7;
	}
	
	
	#main .news .newsCont .newsList li:nth-child(1),
	#main .news .newsCont .newsList li:nth-child(2){
		margin-top:0;
	}
	
	
	#main .news .newsCont .newsList li .subject{
		font-size:16px;
		width:100%;
		color:#222;
		line-height:22px;
		height:45px;
		overflow:Hidden;
		word-break:keep-all;
	}
	
	#main .news .newsCont .newsList li .date{
		font-size:15px;
		width:100%;
		color:#777;
		margin-top:50px;
	}
	

}












@media screen and (max-width:767px) {
	#main{
		width:100%;
	}
	
	#main .visual{
		width:100%;
		position:relative;
	}

	#main .visual .mainVisual{
		width:100%;
	}
	
	#main .visual .mainVisual li{
		width:100%;
		position:relative;
	}
	
	#main .visual .mainVisual li .bg{
		width:100%;
	}
	
	#main .visual .mainVisual li .bg img{
		width:150%;
		position:relative;
		left:50%;
		margin-left:-50%;
		height: 400px;
	}
	
	#main .visual .mainVisual li .cont{
		width:100%;
		position:absolute;
		padding:0px 3%;
		top:50%;
		transform:translateY(-50%);
		z-index:1;
	}
	
	#main .visual .mainVisual li .cont .title{
		font-size:34px;
		color:#000;
		font-weighT:bold;
		font-family:'lato','nanumsquare';
	}

	#main .visual .mainVisual li .bg .slide-substance{
		font-size:24px;
	}

	#main .visual .mainVisual li .bg .slide-substance .dream-span-two{
		font-size: 32px;
	}

	
	#main .visual .mainVisual li .cont .substance{
		font-size:18px;
		color:#000;
		margin-top:20px;
		font-weight:300;
	}
	
	#main .visual .mainVisual li .cont .substance span{
		font-weight:bold;
	}
	
	
	
	#main .visual .mainVisual .slick-prev{
		display: none !important;
	}
	
	
	#main .visual .mainVisual .slick-next{
		display: none !important;
	}
	
	
	#main .mainTitle{
		width:100%;
		text-align:Center;
		font-size:26px;
		color:#222;
		font-weight:bold;
	}
	
	
	
	
	
	#main .golf{
		width:100%;
		margin-top:4em;
		text-align:Center;
		position:relative;
	}
	
	#main .golf .golfTab{
		width:100%;
		margin-top:50px;
		text-align:Center;
	}
	
	
	#main .golf .golfTab li{
		width:90px;
		display:inline-block;
		background:#f7f7f7;
		text-align:center;
		padding:15px 0px;
		font-size:15px;
		color:#222;
		border-radius:50px;
		cursor:pointer;
	}
	
	
	#main .golf .more{
		text-align:Center;
		font-size:15px;
		color:#004f86;
		padding:0px 5px 2px;
		border-bottom:1px solid #004f86;
		display:inline-block;
		margin-top:50px;
	}
	
	
	#main .golf .more a{
		color:#004f86;
	}
	
	#main .golf .more span{
		margin-left:5px;
	}
	
	#main .golf .golfCont{
		position:absolute;
		widtH:100%;
		bottom:0;
		margin-top:50px;
		display: none;		
	}	
	#main .golf .golfCont.on {	
		position: relative;
		display: block;		
	}	
	
	#main .golf .golfVisual{
		width:100%;
		position:relative;
	}



	#main .golf .golfVisual .slick-prev{
		background:url(../images/golfVisual_prev.png) no-repeat;
		width:20px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		left:50%;
		margin-left:-270px;
		top:35%;
		transform:translateY(-50%);
		z-index:2;
		cursor:pointer;
	}
	
	
	#main .golf .golfVisual .slick-next{
		background:url(../images/golfVisual_Next.png) no-repeat;
		width:20px;
		height:38px;
		border:0;
		text-indent:-9999px;
		position:absolute;
		right:50%;
		margin-right:-270px;
		transform:translateY(-50%);
		top:35%;
		cursor:pointer;
	}


	#main .golf .golfVisual li{
		/* margin:0px 15px; */
	}
	
	
	#main .golf .golfVisual li .thum{
		width:100%;
		filter:grayscale(1);
		opacity:0.5;
		transform:scale(0.8);
	}
	
	
	#main .golf .golfVisual .thum img{
		width:100%;
		object-fit: cover;
		object-position: center center;
	}
	
	#main .golf .golfVisual li .textCont{
		width:100%;
		margin-top:2em;
		display:none;
	}
	
	#main .golf .golfVisual li .textCont .po{
		font-size:14px;
		color:#777777;
		text-align:Center;
		font-family:'lato','nanumsquare';
	}
	
	#main .golf .golfVisual li .textCont .subj{
		font-size:18px;
		color:#222;
		text-align:Center;
		font-family:'lato','nanumsquare';
		font-weight:bold;
		margin-top:10px;
	}
	
	
	#main .golf .golfVisual li .textCont .subs{
		font-size:16px;
		color:#444;
		text-align:Center;
		margin-top:30px;
		line-height:26px;
		word-break:keep-all;
	}
	
	
	#main .golf .golfVisual li .textCont .arl{
		font-size:14px;
		color:#004f86;
		text-align:Center;
		margin-top:15px;
		line-height:24px;
	}
	
	
	
	#main .golf .golfVisual .slick-center .thum{
		width:100%;
		filter:none;
		opacity:1;
		transform:scale(1);
	}
	
	
	#main .golf .golfVisual .slick-center .textCont{
		display:block;
	}
	
	
	
	


	#main .package{
		width:100%;
		background:#f7f7f7;
		padding:4em 0px;
		margin-top:0em;
	}
	
	#main .package .inner{
		width:94%;
		margin:0 auto;
	}
	
	
	#main .package .inner .packageList{
		width:100%;
		margin-top:2em;
		display:inline-block;
	}
	
	#main .package .inner .packageList li{
		width:49%;
		display:inline-block;
		margin-left:2%;
		float:left;
		margin-top:8px
	}
	
	#main .package .inner .packageList li:nth-child(2n+1){
		margin-left:0px;
	}
	
	#main .package .inner .packageList li .thum{
		width:100%;
	}
	
	#main .package .inner .packageList li .thum img{
		width:100%;
	}
	
	#main .package .inner .packageList li .bat{
		width:50px;
		position:relative;
		margin-top:-12px;
		background:#004f86;
		color:#fff;
		text-align:center;
		margin-left:1em;
		padding:5px 0px;
		font-family:'lato';
		font-size:12px;
		z-index:1;
	}
	
	#main .package .inner .packageList li .textArea{
		width:100%;
		background:#fff;
		padding:1.5em 1em;
		box-sizing:border-box;
		margin-top:-12px;
	}
	
	#main .package .inner .packageList li .textArea .subj{
		font-size:16px;
		color:#222;
		font-weight:bold;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	
	#main .package .inner .packageList li .textArea .subs{
		font-size:13px;
		color:#777777;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		margin-top:8px;
	}
	
	#main .package .inner .packageList li .textArea .price{
		font-size:16px;
		color:#d30000;
		width:100%;
		margin-top:1.5em;
		font-family:'lato','nanumsquare';
	}
	
		
	
	
	#main .promotion{
    height: 6rem;
		width:94%;
		margin:0em auto 0;
	}
	
	#main .promotion .thum{
		width:100%;
    background-image: url("/public/assets/images/main/members.png");
	}
	
	#main .promotion .promotionList .textArea{
		width:100%;
		position:relative;
		right:0px;
		bottom:0px;
		text-align:right;
		padding:1em;
		box-sizing:border-box;
	}
	
	#main .promotion .textArea .subj{
    width: 100%;
    position: relative;
		font-size:32px;
		color:#091856;
		font-weight:bold;
		margin-top:10px;
    text-align: center;
	}

  #main .promotion .textArea .btn{
    font-size:18px;
    padding: 10px;
    color:#fff;
    background-color: rgb(80, 80, 80);
    font-weight:300;
    margin-top:0px;
    border: 1px solid #777777;
    border-radius: 5px;
    cursor: hand;
  }


	
	
	#main .news{
		width:94%;
		margin:100px auto;
	}
	
	#main .news .titleLine{
		width:100%;
		display:inline-block;
	}
	
	#main .news .titleLine .titleArea{
		display:inline-block;
	}
	
	#main .news .titleLine .titleArea .en{
		font-size:16px;
		color:#777;
	}
	
	#main .news .titleLine .titleArea .title{
		font-size:26px;
		margin-top:10px;
		color:#222222;
		font-weight:bold;
	}
	
	#main .news .titleLine .more{
		display:inline-block;
		float:right;
		margin-top:27px;
	}
	
	#main .news .titleLine .more .text{
		display:inline-block;
		vertical-align:middle;
		font-size:15px;
		color:#004f86
	}
	
	#main .news .titleLine .more .ico{
		width:20px;
		display:Inline-block;
		vertical-align:middle;
	}
	
	#main .news .titleLine .more .ico img{
		width:100%;
	}
	
	
	#main .news .newsCont{
		width:100%;
		display:inline-block;
		margin-top:1.5em;
	}
	
	#main .news .newsCont .notice{
		width:100%;
		display:inline-block;
		float:left;
		position:relative;
	}
	
	#main .news .newsCont .notice .bg{
		width:100%;
		height:100%;
		overflow:hidden;
	}
	
	#main .news .newsCont .notice .bg img{
		height:100%;
	}
	
	#main .news .newsCont .cont{
		width:90%;
		position:absolute;
		padding:1.5em;
		box-sizing:border-box;
		left:0;
		bottom:0;
		background:#fff;
	}
	
	#main .news .newsCont .cont .bat{
		width:50px;
		background:#004f86;
		color:#fff;
		text-align:center;
		padding:6px 0px;
		font-size:13px;
		border-radius:50px;
	}
	
	#main .news .newsCont .cont .subject{
		font-size:18px;
		color:#222;
		margin-top:10px;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-weight:bold;
	}
	
	#main .news .newsCont .cont .substance{
		font-size:15px;
		color:#444;
		margin-top:15px;
		width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		display:none;
	}
	
	
	#main .news .newsCont .cont .date{
		font-size:15px;
		color:#999;
		margin-top:10px;
		font-family:'lato'
	}
	
	#main .news .newsCont .newsList{
		width:100%;
		display:inline-block;
		margin-left:0%;
	}
	
	#main .news .newsCont .newsList li{
		width:49%;
		display:inline-block;
		margin-left:2%;
		float:left;
		border:1px solid #e5e5e5;
		box-sizing:border-box;
		margin-top:5px;
		padding:1.5em;
		
	}
	
	#main .news .newsCont .newsList li:nth-child(2n+1){
		margin-left:0%;
	}
	
	#main .news .newsCont .newsList li:nth-child(2),
	#main .news .newsCont .newsList li:nth-child(3){
		background:#f7f7f7;
	}
	
	
	#main .news .newsCont .newsList li:nth-child(1),
	#main .news .newsCont .newsList li:nth-child(2){
		margin-top:15px;
	}
	
	
	#main .news .newsCont .newsList li .subject{
		font-size:16px;
		width:100%;
		color:#222;
		line-height:22px;
		height:45px;
		overflow:Hidden;
		word-break:keep-all;
	}
	
	#main .news .newsCont .newsList li .date{
		font-size:15px;
		width:100%;
		color:#777;
		margin-top:50px;
	}
	

}


































































































































































































































































