#sub .golfgogo .introduce{
	width:100%;
	display:inline-block;
	text-align: center;
}

#sub .golfgogo .introduce .intro-text{
	/* width:60%; */
	padding: 0px 0px 160px 0px;

	font-size:35px;
	/* font-size:2.3rem; */
	font-family: 'Gowun Batang', serif;
	color: #009dc0;
	word-break: keep-all;
}

#sub .golfgogo .introduce .chairman-box{
  padding: 0px 0;
	/* width:60%; */
}


#sub .golfgogo .introduce .chairman-box .intro-chairman{
	/* width:60%; */
	width:50%;
	float:left;
    text-align: left;
}

#sub .golfgogo .introduce .chairman-box .intro-chairman img{
	/* width:60%; */
	width:75%;
}

#sub .golfgogo .introduce .chairman-text{
	text-align: left;
	width:50%;
	float:left;
	word-break: break-all;
    word-spacing: 2px;
}

#sub .golfgogo .introduce .chairman-text p{
	/* width:60%; */
	font-size: 20px;
	font-weight: 300;
}


#sub .golfgogo .introduce .chairman-text p .chairman-text-span{
	/* width:60%; */
	font-size: 25px;
	font-weight: 600;
	font-family: 'Gowun Batang', serif;
}

#sub .golfgogo .introduce .chairman-text p .chairman-text-obj{
	/* width:60%; */
	font-size: 20px;
	font-weight: 400;
}

#sub .golfgogo .introduce .chairman-text .chairman-text-sign{
	margin-top: 50px;
	text-align: right;
}

#sub .golfgogo .introduce .chairman-text .chairman-text-sign p{
	font-size: 18px;
	font-weight: 600;
}
#sub .golfgogo .introduce .chairman-text .chairman-text-sign span{
	font-size: 25px;
	font-weight: 700;
	font-family: 'Gowun Batang', serif;
}


#sub .golfgogo .introduce .bg{
	/* width:60%; */
	width:50%;
	float:left;
}

#sub .golfgogo .introduce .bg img{
	width:40%;
}



#sub .golfgogo .introduce .cont{
	width:100%;
	position:absolute;
	top:50%;
	transform:translateY(-50%);
}


#sub .golfgogo .introduce .cont .inner{
	width:50%;
	padding-right:50px;
	box-sizing:border-box;
}

#sub .golfgogo .introduce .cont .inner .enSubj{
	font-size:20px;
	color:#444;
}

#sub .golfgogo .introduce .cont .inner .nm{
	font-size:40px;
	color:#222;
	margin-top:30px;
}
#sub .golfgogo .introduce .cont .inner .nm span{
	font-weight:bold;
	display:block;
}


#sub .golfgogo .introduce .cont .inner .subj{
	width:100%;
	margin-top:30px;
	font-size:24px;
	font-weight:bold;
	color:#222;
	line-height:40px;
}


#sub .golfgogo .introduce .cont .inner .subs{
	font-size:16px;
	color:#444;
	margin-top:30px;
	line-height:24px;
	word-break:keep-all;
}

@media screen and (max-width: 1280px) {
	#sub .golfgogo .introduce .intro-text{
		padding: 0px 0px 50px 0px;
	
		font-size:32px;
	}

	#sub .golfgogo .introduce .chairman-box .intro-chairman{
		width:100%;
		text-align: center;
	}

	#sub .golfgogo .introduce .chairman-box .intro-chairman img{
		width: 50%;
	}

	#sub .golfgogo .introduce .chairman-text{
		width:100%;
	}
}

@media screen and (max-width: 767px) {

	#sub .golfgogo .introduce{
		width:100%;
		
	}

	#sub .golfgogo .introduce .intro-text{
		padding: 0px 10px 50px 10px;
		font-size:25px;
	}

	#sub .golfgogo .introduce .chairman-box{
		padding: 0px 20px 50px 20px;
		
	}

	#sub .golfgogo .introduce .chairman-box .intro-chairman{
		width:100%;
		text-align: center;
	}

	#sub .golfgogo .introduce .chairman-box .intro-chairman img{
		width: 70%;
	}

	#sub .golfgogo .introduce .chairman-text p{
		word-break: keep-all;
	}
}

