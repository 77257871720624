#sub .golfgogo .member{
	width:100%;
	display:inline-block;
	text-align: center;
}

#sub .golfgogo .member .member-info {
	font-size: 50px;
	font-family: 'Noto Sans KR', sans-serif;
	margin-bottom: 50px;
}

#sub .golfgogo .member hr {
	display: block;
	margin: 0px auto;
	width: 30%;
}

#sub .golfgogo .member table,th,td{
	border: 1px solid white;
	padding: 30px;
}

/* #sub .golfgogo .member table{
	margin-top: 100px;
	padding: 30px;
} */

#sub .golfgogo .member th{
	color: white;
	background-color: #0075c1;
}

#sub .golfgogo .member td{
	background-color: #f3f3f3;
	text-align: center;
}


@media screen and (max-width: 767px){
	#sub .golfgogo .member table,th,td{
		border: 1px solid white;
		padding: 10px 0px;
	}

	#sub .golfgogo .member th{
		font-size: 10px;
	}

	#sub .golfgogo .member td{
		font-size: 10px;
	}
}