#sub .golfgogo .sponsor{
	width:100%;
	display:inline-block;
	text-align: center;
}

#sub .golfgogo .sponsor table,th,td{
	border: 1px solid white;
	padding: 30px;
}

/* #sub .golfgogo .sponsor table{
	margin-top: 100px;
	padding: 30px;
} */

#sub .golfgogo .sponsor th{
	color: white;
	background-color: #0075c1;
}

#sub .golfgogo .sponsor td{
	background-color: #f3f3f3;
	text-align: center;
}

#sub .golfgogo .sponsor td:last-child{
	background-color: #f3f3f3;
	text-align: center;
}

#sub .golfgogo .sponsor .last-tr td{
  background-color: #d3d3d3;
}

#sub .golfgogo .sponsor .last-tr td:last-child{
  text-align: right;
  background-color: #d3d3d3;
  padding-right: 50px;
}


@media screen and (max-width: 767px){
	#sub .golfgogo .sponsor table,th,td{
		border: 1px solid white;
		padding: 10px 0px;
	}

	#sub .golfgogo .sponsor th{
		font-size: 10px;
	}

	#sub .golfgogo .sponsor td{
		font-size: 10px;
	}
}