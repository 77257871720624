#sub .golfgogo .direction{
	width:100%;
	display:inline-block;
	text-align: center;
}

#sub .golfgogo .direction .direction-box{
	width: 100%;
}

#sub .golfgogo .direction .direction-box .direction-map{
	width: 50%;
	float: left;
}

#sub .golfgogo .direction .direction-box .direction-text{
	width: 50%;
	float: left;
	word-break: keep-all;
	word-spacing: 2px;
	
}

#sub .golfgogo .direction .direction-box .direction-text .direction-text-section{
	clear: both;
	height: 100px;
	padding: 40px;
}

#sub .golfgogo .direction .direction-box .direction-text .direction-text-section .direction-icon{
	width: 20%;
	float: left;
}


#sub .golfgogo .direction .direction-box .direction-text .direction-text-section .direction-icon img{
	width: 50%;
	padding: 10px;
	border-radius: 50px;
}

.icon-red{
	background-color: #F03B2A;
}

.icon-green{
	background-color: #009dc0;
}

.icon-blue{
	background-color: #108DC4;
}




#sub .golfgogo .direction .direction-box .direction-text 
.direction-text-section .direction-content{
	width: 80%;
	float: left;
	text-align: left;
}

#sub .golfgogo .direction .direction-box .direction-text 
.direction-text-section .direction-content p.title{
	word-spacing: 19px;
	font-size: 22px;
	font-weight: 500;
}

#sub .golfgogo .direction .direction-box .direction-text 
.direction-text-section .direction-content p.content{
	font-weight: 300;
	margin-top: 5px;
}

@media screen and (max-width: 1280px) {
	
}

@media screen and (max-width: 767px) {

	#sub .golfgogo .direction{
		width:100%;
		display:inline-block;
		text-align: center;
	}
	
	#sub .golfgogo .direction .direction-box{
		width: 100%;
	}
	
	#sub .golfgogo .direction .direction-box .direction-map{
		width: 100%;
	}
	
	#sub .golfgogo .direction .direction-box .direction-text{
		margin-top: 20px;
		width: 100%;
		word-break: keep-all;
		word-spacing: 2px;
		
	}
	
	#sub .golfgogo .direction .direction-box .direction-text .direction-text-section{
		height: 100px;
		padding: 20px 0px;
	}
	
	#sub .golfgogo .direction .direction-box .direction-text 
	.direction-text-section .direction-content p.title{
	}
}

