#sub .golfgogo .operation {
	width:100%;
	display:inline-block;
	text-align: center;
	font-family: 'Noto Sans KR', sans-serif;
}

#sub .golfgogo .operation > div:not(:first-of-type){
	margin-top: 200px;
}

#sub .golfgogo .operation .general-rules {
	padding: 30px;
	font-size: 50px;
	font-family: 'Noto Sans KR', sans-serif;
}

#sub .golfgogo .operation hr {
	display: block;
	margin: 0px auto;
	width: 30%;
}


#sub .golfgogo .operation .general-rules text{
	border: 1px solid black;
}

#sub .golfgogo .operation .general-article {
	text-align: left;
	margin: 50px 0px 20px 0px;
	font-size: 25px;
	font-family: 'Noto Sans KR', sans-serif;
}

#sub .golfgogo .operation .article-content {
	text-align: left;
	margin: 0px 0px 70px 20px;
	font-size: 20px;
	font-weight: 300;
	font-family: 'Noto Sans KR', sans-serif;
}

@media screen and (max-width: 767px) {
	#sub .golfgogo .operation {
		width: 100%;
		word-break: keep-all;
	}
	#sub .golfgogo .operation .mobile_padding{ 
		padding: 0px 20px 50px 20px;
	}

	#sub .golfgogo .operation .general-rules {
		padding: 30px;
		font-size: 40px;
	}



}