.admin-enter--wrapper {
  height: 100vh;
  background: url(../../assets/images/admin/login_bg.jpg);  
}

.admin-enter--container {
  max-width: 500px;
  min-width: 200px;
  width: 100%;  
  margin: 0 auto;  
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.admin-enter--container form input::placeholder {
  color: #333;
}

.admin-enter--container form span.error-message {
  color: red;
  font-size: .8rem;
  text-align: center;
}

.admin-enter {  
  border: 1px solid #999;
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
}
.admin-enter--container h1 {
  color: #fff;
  font-family: 'nanumsquare';
  font-style: italic;
  margin-bottom: .5rem;
  padding-left: .5rem;
}

.input-hook.error {  
  outline-offset: -0.2rem !important;  
  outline: 1px solid #f00 !important;
}
.input-hook.error:focus {
  border: 1px solid #f00;
}
.input-hook:focus {
  border: 1px solid #ccc;
}

.input-hook__label {
  padding-top: 1rem;
  padding-bottom:.2rem;
}

.input-hook {  
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: .2rem;  
}

.input-hook.content {  
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: .2rem;  
}

.admin-enter__submit_btn {
  background-color: #004f86;
  color: #eee;
  font-size: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: .2rem;
  cursor: pointer;
}
.admin-enter__submit_btn:hover,
.admin-enter__submit_btn:focus {
  background-color: #005f86;
}

.tab-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;  
}

.tab-area .btn {
  padding: .4rem 1rem;
  cursor: pointer;
}

.tab-area .btn:hover,
.tab-area .btn:focus {
  background-color: #ddd;
}

.hook_form {
  display: flex;
  flex-direction: column;
}

.hook_form.notice-write {  
  width: 100%;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.writeContent {
  padding: 0rem;
  border-radius: 0.2rem;
  line-height:120%;
  width:100%;
  text-align:left;
  vertical-align: middle;
  height: 500px;

}

.hook_form .notice-content {  
  width: 100%;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.input-unit {
  display: flex;
  flex-direction: column;
  height: 100px;
  padding: 5px 0px;
}