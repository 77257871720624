#sub .golfgogo .chairmanP {
	width:100%;
	display:inline-block;
	text-align: center;
	margin-bottom: 100px;
}

#sub .golfgogo .chairmanP .chairmanP-text{
	/* width:60%; */
	padding: 160px 0px;
	font-size:40px;
	font-weight: 800;
	color: #009dc0;
}

#sub .golfgogo .chairmanP .chairmanP-box{
	/* width:60%; */
}


#sub .golfgogo .chairmanP .chairmanP-box .intro-chairman{
}

#sub .golfgogo .chairmanP .chairmanP-box .intro-chairman img{
}
