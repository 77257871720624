/* 메뉴 탭 */
#sub .tabMenu-2 .tabMenu { width:calc(49.9999999% - 1px); }
#sub .tabMenu-3 .tabMenu { width:calc(33.33% - 1px); }
#sub .tabMenu-4 .tabMenu { width:calc(24.99% - 1px); }
#sub .tabMenu-5 .tabMenu { width:calc(19.999% - 1px); }
#sub .tabMenu-6 .tabMenu { width:calc(16.66% - 1px); }
#sub .tabMenu-7 .tabMenu { width:calc(14.28% - 1px); }
#sub .tabMenu-8 .tabMenu { width:calc(12.50% - 1px); }

/* 서브화면 카테고리 탭 */
#sub .contents .tabCtgWrap { margin: 30px 10px 10px; }
#sub .contents .tabCtg { float: left; min-width:80px; height: 40px; line-height: 40px; padding:0 10px; margin-left:-1px; border:1px solid #dfdfdf; border-bottom:1px solid #dfdfdf; font-size: 14px; font-weight:300; color: #777; text-align: center; }
#sub .contents .tabCtgWrap .on { border:1px solid #25237b; color:#fff; background:#25237b; }

/* 서브화면 테마별 카테고리 탭 */
#sub .contents .tabCtgDocWrap { margin: 30px 0 10px; }
#sub .contents .tabCtgDoc { float: left; min-width:80px; height: 40px; line-height: 40px; margin-left:-1px; border:1px solid #dfdfdf; border-bottom:1px solid #dfdfdf; font-size: 14px; font-weight:300; color: #777; text-align: center; }
#sub .contents .tabCtgDocWrap .on { border:1px solid #555; color:#fff; background:#555; }

/* 메뉴 탭 */
#sub .tabMenu-4 .tabCtgDoc { width:calc(24.99% - 1px); }
#sub .tabMenu-6 .tabCtgDoc { width:calc(16.66% - 1px); }

/* 서브화면 메뉴 탭 */
#sub .tabMenuWrap { width:100%; margin: 20px 0 30px; border-bottom:2px solid #222; }
#sub .tabMenuWrap .tabMenu { float: left; z-index:1; height: 50px; line-height: 50px; margin-left:-1px; margin-bottom:-1px; border:1px solid #f6f6f6; border-bottom:1px solid #555; background:#f6f6f6; font-size: 16px; font-weight:500; color: #777; text-align: center; }
#sub .tabMenuWrap a:hover .tabMenu, .tabMenuWrap a:focus .tabMenu, .tabMenuWrap a:active .tabMenu  { color:#fff !important; }
#sub .tabMenuWrap .current span { z-index:2; border:1px solid #555; border-bottom:1px solid #555; color:#fff; background:#555; }
#sub .tabMenuWrap .current a:hover span, .tabMenuWrap .current a:focus span, .tabMenuWrap .current a:active span {color:#fff;}

/* 밑줄 에니메이션 버튼 */
#sub .tabMenuWrap .btnAni { display: inline-block; position: relative; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; transition: all 0.3s; cursor: pointer; }
#sub .tabMenuWrap .btnAni:after { position: absolute; z-index: -1; content: ''; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; transition: all 0.3s; }
#sub .tabMenuWrap .btnAniLine:after { width: 100%; height:0; bottom: 0; left: 0; }
#sub .tabMenuWrap .btnAniLine:hover { color:#fff !important; border:1px solid #555 !important; border-bottom:1px solid #555 !important; background:#555 !important;  }
#sub .tabMenuWrap .btnAniLine:hover:after {  height:100%;  }

#sub .golfgogo .history{
	width:100%;
	display:inline-block;
	text-align: center;
	padding: 0px 0px 150px 0px;
}

#sub .golfgogo .history .history-tab li{
	display:inline-block;
	padding: 0px 50px 0px 50px ;
	font-size: 24px;
	border-bottom: 2px solid black;
}

#sub .golfgogo .history .tab-view {
	margin-top: 43px;
	border-bottom: 1px solid gray;
}

#sub .golfgogo .history .tab-view h2{
	padding: 10px 0px;
}

#sub .golfgogo .history .history-table{
	white-space: pre-wrap;
}

#sub .golfgogo .history .history-table th{
	padding: 15px 30px 0px 0px !important;
	white-space: pre-wrap;
}

#sub .golfgogo .history .history-table th{
	text-align: right;
	vertical-align: top;
	font-size: 50px;
	width: 23%;
	color: #999;
}

#sub .golfgogo .history .history-table td{
	font-size: 20px;
	text-align: left;
	width: 20%;
	border-bottom: 1px solid lightgrey !important;
}

#sub .golfgogo .history .history-table td.table_date{
	vertical-align: top;
}

#sub .golfgogo .history .history-table td:last-child{
	width: auto;
}




@media screen and (max-width: 1280px) {

	#sub .golfgogo .history .history-table th{
		display: none;
		
	}
	
	#sub .golfgogo .history .history-table td{
		font-size: 14px;
		text-align: center;
		width: 30%;
		border-bottom: 1px solid #999 !important;
		word-break: keep-all;
	}

	#sub .golfgogo .history .history-table td:last-child{
		text-align: left;
	}


}

@media screen and (max-width: 767px) {

}

