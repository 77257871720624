.accounts--wrapper {
  display: flex;
  justify-content: center;
  padding: 0px 15px;
}
.hook_form.log_in {  
  width: 100%;
  max-width: 850px;
  margin-top: 8rem;
  margin-bottom: 8rem;
}