.total {
  display: flex;
  justify-content: space-between;
}
.write-btn {
  padding: .4rem 1rem;
  cursor: pointer;
}

.boardList.write-on .head {display: flex; justify-content: center;}
.boardList.write-on .noticeTitle {font-size: large;}
.input--wrapper { width: 100%;}
.input--container { display: flex; flex-direction: column; width: 100%;}
.boardList.write-on .btnArea--wrapper {justify-content: flex-end; }
.boardList.write-on .btnArea {display: flex; justify-content: flex-end;}
.title_input {font-size: 1.2rem; font-weight: 800; margin-bottom: 1rem;}
#sub .bbCont .boardListArea .boardList.write-on .btnArea li:first-child { border-top:none; }
#sub .bbCont .boardListArea .boardList.write-on .btnArea li {padding: .5rem; border-bottom:none;}
#sub .bbCont .boardListArea .boardList.write-on .btnArea li button {padding: .4rem 1rem; cursor: pointer;}

#sub .bbCont .boardListArea .boardList .modify--container {display: flex; justify-content: space-around;}
#sub .bbCont .boardListArea .boardList .modify--container .edit_btn {width: 2rem; margin-right: .2rem; cursor: pointer;}
#sub .bbCont .boardListArea .boardList .modify--container .delete_btn {width: 2rem; cursor: pointer;}

#sub .bbCont .boardListArea .boardList li.head .subj.onEdit {width: 62%;}
.boardViewArea.editMode .title_input {padding: 1rem; border: 1px solid #ccc;  width: 80%;}
.boardViewArea.editMode .content_input {padding: 1rem; border: 1px solid #ccc; width: 100%; height: 90%;}

#sub .bbCont .boardViewArea .viewBox .btnArea :is(li:hover, li:focus )  {   
  background-color: #eee;   
}

#sub .bbCont .boardViewArea .viewBox .viewCont {
  height: auto;
}

/* #sub .bbCont .boardViewArea .viewBox .btnArea li .tx.hover {color: #fff;}
#sub .bbCont .boardViewArea .viewBox .btnArea li a svg.hover {color: #fff} */