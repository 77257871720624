@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');

#sub{
	width:100%;
	box-sizing:border-box;
}

#sub .page-info{
	position: relative;
}

#sub .page-info .page-info-bg img{
	width:100%;
	height: 400px;
	opacity: 0.9;
}

#sub .page-info .page-info-bg-opacity {
	position: absolute;
	top: 0%;
	left: 0%;
	width:100%;
	height: 100%;
	background-color: black;
	opacity: 0.5;
}


#sub .page-info .page-info-text{
	position: absolute;
	color: white;
	top: 50%;
	left: 50%;
	transform: translate( -50%, -50% );
	text-align: center;
	word-break: keep-all;
}

#sub .page-info .page-info-text p{
	font-size: 65px;
	font-weight: bold;
}

#sub .page-info .page-info-text span{
	font-size: 20px;
	font-weight: lighter;
}



#sub .subQTline{
	width:100%;
	box-sizing:border-box;
	display:inline-block;
	background:#f7f7f7;
	text-align: center;
}

#sub .subQTline .titleA{
	display:inline-block;
	background:#fff;
	border-right:1px solid #e5e5e5;
	padding:15px 20px;
	box-sizing:border-box;
	height:77px;
	vertical-align:top;
}

#sub .subQTline .titleA .nm{
	font-size:18px;
	color:#222;
	font-weight:bold;
	font-family:'lato'
}

#sub .subQTline .titleA .tit{
	font-size:18px;
	color:#222;
	font-weight:300;
	font-family:'lato';
	margin-top:5px;
}

#sub .subQTline .quickMenu{
	text-align: center;
}

#sub .subQTline .quickMenu li{
	vertical-align:top;
	display: inline-block;
	width:150px;
	border-right:1px solid #e5e5e5;
	box-sizing:border-box;
	word-break: keep-all;
	padding: 15px 0px;
	height:inherit;
}

#sub .subQTline .quickMenu li:last-child{
	border:0px;
}

#sub .subQTline .quickMenu li .ico{
	width:34px;
	margin:0 auto;
}

#sub .subQTline .quickMenu li .ico img{
	width:100%;
}

#sub .subQTline .quickMenu li .text{
	font-size:15PX;
	text-align:center;
	color:#444;
	margin-top:5px;
}

#sub .subQTline .quickMenu li.quick-list-on{
	background-color: white;
}

#sub .subQTline .nav{
	display:inline-block;
	float:Right;
	margin-top:20px;
}

#sub .subQTline .nav .ico{
	display:inline-block;
	width:45px;
	vertical-align:middle;
}

#sub .subQTline .nav .tx{
	display:inline-block;
	font-size:16px;
	vertical-align:middle;
	color:#777;
	font-family:'lato','nanumsquare';
}

#sub .subQTline .nav div{
	position:relative;
	margin:0px 15px;
}


#sub .subQTline .nav div:last-child{
	margin:0px 0px 0px 15px;
}

#sub .subQTline .nav div:after{
	content:'';
	background:url(../images/sub/navArrow.png);
	width:7px;
	height:13px;
	position:absolute;
	top:50%;
	transform:translateY(-50%);
	margin-left:15px;
}


#sub .subQTline .nav div:last-child:after{
	/* background:url(); */
}

#sub .pageInnerTitle {
	text-align: center;
	padding: 150px 0px 0px 0px;
}

#sub .pageInnerTitle span{
	text-align: center;
	border-bottom: 1px solid gray;
	font-size: 40px;
	font-weight: 500;
	padding: 10px 0px;
}

#sub .paging{
	width:100%;
	text-align:center;
	margin-top:50px;
}

#sub .paging li{
	width:24px;
	line-height:24px;
	display:inline-block;
	margin:0px 5px;
}

#sub .paging li a{
	color:#c1c1c1;
}

#sub .paging .on a{
	color:#004f86;
	text-decoration:underline;
	font-weight:bold;
	
}

#sub .paging li:first-child,
#sub .paging li:last-child{
	border:1px solid #e5e5e5;
	box-sizing:border-box;
}


#sub .paging li img{
	width:100%;
}


#sub .subTitleImg{
	width:100%;
	position:relative;
}

#sub .subTitleImg .bg{
	width:100%;
}

#sub .subTitleImg .bg img{
	width:100%;
}

#sub .subTitleImg .textBox{
	width:50%;
	position:absolute;
	background:rgba(0,0,0,0.5);
	padding:50px 80px;
	box-sizing:border-box;
	bottom:0;
}

#sub .subTitleImg .textBox .lcNm{
	font-size:18px;
	color:#fff;
	font-family:'lato','nanumsquare';
}

#sub .subTitleImg .textBox .tit{
	font-size:36px;
	color:#fff;
	font-weight:bold;
	margin-top:30px;
	word-break:keep-all;
	line-height:46px;

}

#sub .subTitleImg .textBox .subT{
	font-size:18px;
	color:#fff;
	margin-top:15px
}


#sub .subTitleArea{
	width:100%;
}

#sub .subTitleArea .tit h1 {
	font-size:36px;
	text-align:center;
	font-weight:bold;
	color:#222;
}

#sub .subTitleArea .tit .hrH1 {
  content: "";
  bottom: 0;
  width: 1px;
  height: 50px;
  margin: 30px 0 30px 50%;
  background: #999;
}


/*
#sub .lcMenu{
	width:100%;
	margin-top:30px;
	display:flex;
}

#sub .lcMenu li{
	width:100%;
	display:inline-block;
	border-right:1px solid #e5e5e5;
	border-top:1px solid #e5e5e5;
	border-bottom:1px solid #e5e5e5;
	Text-align:center;
	font-size:18px;
	padding:20px 0px;
}

#sub .lcMenu li a{
	color:#999;
}


#sub .lcMenu li:first-child{
	border-left:1px solid #e5e5e5;
}

#sub .lcMenu .on{
	border-bottom:0;
	color:#222;
	font-weight:bold;
}

#sub .lcMenu .on a{
	color:#222;
} */

#sub .lcMenu{
	width:100%;
	margin-top:30px;
	display:flex;
}

#sub .lcMenu a{
	width:100%;
	display:inline-block;
	border-right:1px solid #e5e5e5;
	border-top:1px solid #e5e5e5;
	border-bottom:1px solid #e5e5e5;
	Text-align:center;
	font-size:18px;
	padding:20px 0px;
}

#sub .lcMenu a li {
	color:#999;
}


#sub .lcMenu a:first-child{
	border-left:1px solid #e5e5e5;
}

#sub .lcMenu .on{
	border-bottom:0;
	color:#222;
	font-weight:bold;
}

#sub .lcMenu .on li{
	color:#222;
}







.deRadio{
	display:inline-block;
}


.deRadio label{
	display: inline-block;
	cursor: pointer;
	font-size: 16px;
	vertical-align:middle;
	color:#777777;
}


.deRadio input[type=radio]{
	display: none;
}

.deRadio label::before {
	content: "";
	display: inline-block;
	width: 22px;
	vertical-align:middle;
	height:22px;
	margin-right:5px;
	background:url("../images/sub/radioOff.png");
	margin-top:-2px;
}



.deRadio input[type=radio]:checked + label::before {
	content: "";
	background:url("../images/sub/radioOn.png");
}



#sub .dimenuArea{
	width:100%;
	margin-top:30px;
	display:inline-block;
}

#sub .dimenuArea .diMenu{
	display:inline-block;
}


#sub .dimenuArea .diMenu li{
	width:180px;
	display:inline-block;
	border:1px solid #e5e5e5;
	box-sizing:border-box;
	/* padding:16px 0px; */
	text-align:Center;
	margin-right:6px;
}

#sub .dimenuArea .diMenu li a{
	display: block;
	padding:16px 14px;
}

#sub .dimenuArea .diMenu li .ico{
	width:27px;
	display:inline-block;
	vertical-align:Middle;
}

#sub .dimenuArea .diMenu li .ico img{
	width:100%;
}

#sub .dimenuArea .diMenu li .text{
	font-size:16px;
	display:inline-block;
	vertical-align:Middle;
	margin-left:4px;
}

#sub .dimenuArea .bookingBtn{
	display:inline-block;
	float:right;
	width:180px;
	padding:20px 0px;
	background:#004f86;
	color:#fff;
	text-align:center;
}
#sub .dimenuArea .bookingBtn a{
	color:#fff;
}


/*boardList*/

#sub .bbCont{
	max-width:1180px;
	margin:100px auto 100px;
}

#sub .bbCont .subjA{
	width:100%;
	display:inline-block;
}

#sub .bbCont .subjA .ensubj{
	font-size:24px;
	color:#444;
}

#sub .bbCont .subjA .subj{
	font-size:48px;
	color:#222;
	margin-top:10px;
}


#sub .bbCont .boardListArea{
	width:100%;
	margin-top:30px;
}

#sub .bbCont .boardListArea .searchBox{
	width:100%;
	background:#f7f7f7;
	padding:20px 50px;
	box-sizing:border-box;
	text-align:center;
}

#sub .bbCont .boardListArea .searchBox .selectbox{
	width:15%;
	padding:15px;
	box-sizing:border-box;
	border-bottom:1px solid #222;
	border-left:0;
	border-right:0;
	border-top:0;
	font-size:16px;
	color:#222;
	background:#f7f7f7;
}

#sub .bbCont .boardListArea .searchBox .textbox{
	width:72%;
	padding:15px;
	box-sizing:border-box;
	border-bottom:1px solid #222;
	border-left:0;
	border-right:0;
	border-top:0;
	font-size:16px;
	color:#222;
	background:#f7f7f7;
	margin-left:0.5%;
}

#sub .bbCont .boardListArea .searchBox .textbox::placeholder{
	color:#999;
}

#sub .bbCont .boardListArea .searchBox .searchbtn{
	width:10%;
	display:inline-block;
	border-radius:50px;
	background:#004f86;
	color:#fff;
	text-align:center;
	font-size:16px;
	padding:15px 0px;
	margin-left:1%;
}

#sub .bbCont .boardListArea .total{
	width:100%;
	font-size:15px;
	color:#999;
	margin-top:50px;
}

#sub .bbCont .boardListArea .boardList{
	width:100%;
	margin-top:20px;
}

#sub .bbCont .boardListArea .boardList li,
#sub .bbCont .boardListArea .boardList li a{
	width:100%;
	display:flex;
}

#sub .bbCont .boardListArea .boardList li{
	padding:25px 0px;
	border-bottom:1px solid #e5e5e5;
}

#sub .bbCont .boardListArea .boardList li:first-child{
	border-top:1px solid #222;
}


#sub .bbCont .boardListArea .boardList li div{
	text-align:left;
}


#sub .bbCont .boardListArea .boardList li .num{
	width:8%;
	display:inline-block;
}


#sub .bbCont .boardListArea .boardList .head .subj{
	text-align:center;
}


#sub .bbCont .boardListArea .boardList li .subj{
	width:65%;
	display:inline-block;
	text-align:left;
}

#sub .bbCont .boardListArea .boardList li .subj .subject{
	display:inline-block;
	text-align:left;
	vertical-align:middle;
	max-width:90%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}


#sub .bbCont .boardListArea .boardList li .subj .new{
	display:inline-block;
	text-align:left;
	vertical-align:middle;
	width:16px;
	margin-left:5px;
}

#sub .bbCont .boardListArea .boardList li .subj .new img{
	width:100%;
}

#sub .bbCont .boardListArea .boardList li .writer{
	width:10%;
	display:inline-block;
}

#sub .bbCont .boardListArea .boardList li .date{
	width:10%;
	display:inline-block;
}

#sub .bbCont .boardListArea .boardList li .view{
	width:10%;
	display:inline-block;
}








/*boardView*/


#sub .bbCont .boardViewArea{
	width:100%;
	margin-top:30px;
}

#sub .bbCont .boardViewArea .diArea{
	width:100%;
	padding:40px 20px;
	box-sizing:border-box;
	display:inline-block;
	border-top:1px solid #222;
	margin-left: .5rem;
}

#sub .bbCont .boardViewArea .diArea .subjA{
	display:inline-block;
	width:70%;
	vertical-align:bottom;
}

#sub .bbCont .boardViewArea .diArea .subjA .noticeBat{
	width:55px;
	background:#004f86;
	color:#fff;
	text-align:Center;
	font-size:14px;
	padding:5px 0px;
	border-radius:50px;
}

#sub .bbCont .boardViewArea .diArea .subjA .subj{
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	font-size:20px;
	font-weight:bold;
	margin-top:15px;
}

#sub .bbCont .boardViewArea .diArea .iList{
	display:inline-block;
	width:29%;
	text-align:right;
	vertical-align:bottom;
}


#sub .bbCont .boardViewArea .diArea .iList li{
	display:inline-block;
	padding:0px 10px;
	border-right:1px solid #e5e5e5;
}

#sub .bbCont .boardViewArea .diArea .iList li:last-child{
	padding:0px 0px 0px 10px;
	border-right:0px solid #e5e5e5;
}



#sub .bbCont .boardViewArea .viewBox{
	width:100%;
	border:1px solid #e5e5e5;
	box-sizing:border-box;
}

#sub .bbCont .boardViewArea .viewBox .viewCont{
	width:100%;
	padding:50px 30px;
	box-sizing:border-box;
}


#sub .bbCont .boardViewArea .viewBox .viewCont p{
	margin:0 auto;
	line-height:26px;
}


#sub .bbCont .boardViewArea .viewBox .viewCont .center{
	font-size:16px;
	color:#444;
	text-align:center;
}




#sub .bbCont .boardViewArea .viewBox .viewCont img{
	text-align:Center;
	max-width:1020px;
	width:100%;
	margin:50px auto 50px;
}





#sub .bbCont .boardViewArea .viewBox .btnArea{
	width:100%;
	padding:30px;
	background:#f7f7f7;
	border-top:1px solid #e5e5e5;
	box-sizing:border-box;
	text-align:right;
}

#sub .bbCont .boardViewArea .viewBox .btnArea li{
	display:inline-block;
	background:#fff;
	border-radius:50px;
	border:1px solid #e5e5e5;
	box-sizing:border-box;
	/* padding:15px 30px; */
}
#sub .bbCont .boardViewArea .viewBox .btnArea li a{
	display: block;
	padding:15px 30px;
	cursor: pointer;
}

#sub .bbCont .boardViewArea .viewBox .btnArea li .ico{
	width:17px;
	display:inline-block;
	vertical-align:middle;
}

#sub .bbCont .boardViewArea .viewBox .btnArea li .ico img{
	width:100%;
}


#sub .bbCont .boardViewArea .viewBox .btnArea li .tx{
	font-size:16px;
	display:inline-block;
	vertical-align:Middle;
	margin-left:10px;
	color:#444;
}

#sub .bbCont .boardViewArea .viewBox .btnArea li .tx{
	font-size:16px;
	display:inline-block;
	vertical-align:Middle;
	margin-left:10px;
	color:#444;
}

#sub .bbCont .boardViewArea .boardListArea{
	margin-top:0px;
}

#sub .bbCont .boardViewArea .boardListArea .boardList{
	margin-top:0px;
}


#sub .bbCont .boardViewArea .boardListArea .boardList li:first-child{
	border-top:0;
}


#sub .bbCont .boardViewArea .boardListArea .boardList li .num{
	width:15%;
}


#sub .bbCont .boardViewArea .boardListArea .boardList li .subj{
	width:75%;
}

#sub .bbCont .boardViewArea .boardListArea .boardList li .date{
	width:20%;
}





/*bookingInfo*/

#sub .bbCont .booking{
	margin-top:30px;
	width:100%;
}

#sub .bbCont .booking .inBox{
	width:100%;
	border:1px solid #e5e5e5;
	box-sizing:border-box;
	padding:30px;
}
#sub .bbCont .booking .inBox .tit{
	font-size:20px;
	color:#222;
	font-weight:bold;
}

#sub .bbCont .booking .inBox .inList{
	width:100%;
	margin-top:50px;
}

#sub .bbCont .booking .inBox .inList li{
	width:49.5%;
	display:inline-block;
	vertical-align:Middle;
}

#sub .bbCont .booking .inBox .inList li .ico{
	width:60px;
	display:inline-block;
	vertical-align:middle;
}

#sub .bbCont .booking .inBox .inList li .ico img{
	width:100%;
}

#sub .bbCont .booking .inBox .inList li .txA{
	display:inline-block;
	vertical-align:middle;
	margin-left:15px;
}

#sub .bbCont .booking .inBox .inList li .txA .subj{
	font-size:16px;
	color:#777777;
	font-family:'lato','nanumsquare';
}

#sub .bbCont .booking .inBox .inList li .txA .ct{
	font-size:18px;
	color:#222222;
	font-family:'lato','nanumsquare';
	margin-top:5px;
}



#sub .bbCont .booking .cancleTbox{
	width:100%;
	background:#f7f7f7;
	padding:30px;
	box-sizing:border-box;
	margin-top:20px;
}

#sub .bbCont .booking .cancleTbox .subjLine{
	width:100%;
}

#sub .bbCont .booking .cancleTbox .subjLine .ico{
	width:28px;
	display:inline-block;
	vertical-align:middle;
}

#sub .bbCont .booking .cancleTbox .subjLine .subj{
	font-size:20px;
	font-weight:bold;
	color:#222;
	display:Inline-block;
	vertical-align:Middle;
	margin-left:10px;
}


#sub .bbCont .booking .cancleTbox .subs{
	font-size:16px;
	color:#444;
	margin-top:20px;
	word-break:keep-all;
	line-height:26px;
}


#sub .bbCont .booking .cancleTbox .tList{
	width:100%;
	margin-top:30px;
	padding-left:20px;
}


#sub .bbCont .booking .cancleTbox .tList li{
	width:100%;
	list-style:disc;
	margin-top:10px;
}

#sub .bbCont .booking .cancleTbox .tList li:first-child{
	margin-top:0px;
}


#sub .bbCont .booking .cancleTbox .tList li .subj{
	font-size:16px;
	color:#222;
	font-weight:bold;
	display:inline-block;
	width:26%;
}

#sub .bbCont .booking .cancleTbox .tList li .subs{
	font-size:16px;
	color:#444;
	display:inline-block;
	width:69%;
	margin:0;
}








/*clarkPackageList01 - 패키지리스트*/




#sub .subCont{
	max-width:1180px;
	margin:50px auto 50px;
}

#sub .subCont .container{
	width:100%;
	margin-top:80px;
}

#sub .subCont .container .lcOpLine{
	width:100%;
	display:inline-block;
}

#sub .subCont .container .lcOpLine .lcD2_menu{
	display:inline-block;
	margin-top:7px;
}

#sub .subCont .container .lcOpLine .lcD2_menu li{
	display:inline-block;
	font-size:18px;
	padding:0px 20px;
	border-left:1px solid #e5e5e5;
}
#sub .subCont .container .lcOpLine .lcD2_menu li a{
	color:#c1c1c1;
}
#sub .subCont .container .lcOpLine .lcD2_menu li:first-child{
	padding:0px 20px 0px 0px;
	border-left:0px solid #e5e5e5;
}

#sub .subCont .container .lcOpLine .lcD2_menu .on a{
	color:#004f86;
	font-weight:bold;
}

#sub .subCont .container .lcOpLine .opA{
	display:inline-block;
	float:right;
	
}


#sub .subCont .container .lcOpLine .opA .alignList{
	display:inline-block;
	vertical-align:middle;
}

#sub .subCont .container .lcOpLine .opA .alignList li{
	display:inline-block;
	margin-left:15px;
}


#sub .subCont .container .lcOpLine .opA .listType{
	display:inline-block;
	vertical-align:middle;
	margin-left:30px;
	width:auto;
	margin-top:0;
}

#sub .subCont .container .lcOpLine .opA .listType li{
	width:35px;
	display:inline-block;
	border:1px solid #444;
	box-sizing:border-box;
	float:left;
	opacity:0.2;
	margin-left:-1px;
	cursor: pointer;
}

#sub .subCont .container .lcOpLine .opA .listType li img{
	width:100%;
}

#sub .subCont .container .lcOpLine .opA .listType .on{
	opacity:1;
	
}

#sub .subCont .container .listType{
	width:100%;
	margin-top:15px;
}

#sub .subCont .container .listType dd{
	width:100%;
	padding:50px 0px;
	border-bottom:1px solid #e5e5e5;
	box-sizing:border-box;
}

#sub .subCont .container .listType dd:first-child{
	border-top:1px solid #222;
}



#sub .subCont .container .listType dd .thum{
	width:30%;
	display:inline-block;
	vertical-align:middle;
}

#sub .subCont .container .listType dd .thum img{
	width:100%;
}


#sub .subCont .container .listType dd .txA{
	width:69%;
	display:inline-block;
	vertical-align:middle;
	padding:0px 50px;
	box-sizing:border-box;
}

#sub .subCont .container .listType dd .txA .subject{
	width:100%;
	font-size:20px;
	color:#222;
}

#sub .subCont .container .listType dd .txA .subject span{
	font-weight:bold;
}

#sub .subCont .container .listType dd .txA .subs{
	width:100%;
	font-size:16px;
	color:#222;
	margin-top:10px;
	word-break:keep-all;
	line-height:24px;
}

#sub .subCont .container .listType dd .txA .price{
	width:100%;
	font-size:24px;
	font-weight:bold;
	font-family:'lato','nanumsquare';
	color:#d30000;
	margin-top:15px;
}


#sub .subCont .container .listType dd .txA .price .won{
	font-size:16px;
	font-weight:normal;
}


#sub .subCont .container .listType dd .txA .price .pr{
	font-size:16px;
	font-weight:normal;
	color:#777;
	display:inline-block;
	margin-left:10px;
}


#sub .subCont .container .listType dd .txA .ctSubs{
	font-size:16px;
	color:#444;
	margin-top:45px;
	word-break:keep-all;
	line-height:26px;
}


#sub .subCont .container .listType dd .txA .ctList{
	margin-top:45px;
	width:100%;
}


#sub .subCont .container .listType dd .txA .ctList .subj{
	width:15%;
	display:inline-block;
	vertical-align:top;
	font-size:16px;
	color:#004f86;
}

#sub .subCont .container .listType dd .txA .ctList .subsList{
	width:84%;
	display:inline-block;
	vertical-align:top;
}

#sub .subCont .container .listType dd .txA .ctList .subsList div{
	width:100%;
	font-size:16px;
	color:#444;
	margin-top:10px;
}

#sub .subCont .container .listType dd .txA .ctList .subsList div:first-child{
	margin-top:0px;
}

/*clarkPackageList02 - 패키지리스트02*/


#sub .subCont .container .blockType{
	width:100%;
	margin-top:15px;
	border-top:1px solid #222;
	border-bottom:1px solid #e5e5e5;
	padding:0px 0px 50px 0px;
	display:inline-block;
}



#sub .subCont .container .blockType dd{
	width:380px;
	display:inline-block;
	margin-left:20px;
	/* float:left; */
	margin-top:50px;
}

#sub .subCont .container .blockType dd:nth-child(3n+1){
	margin-left:0px;
}


#sub .subCont .container .blockType dd .thum{
	width:100%;
}

#sub .subCont .container .blockType dd .thum img{
	width:100%;
}

#sub .subCont .container .blockType dd .txA{
	width:100%;
	background:#f7f7f7;
	padding:20px;
	box-sizing:border-box;
}

#sub .subCont .container .blockType dd .txA .subject{
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	font-size:20px;
	color:#222;
}

#sub .subCont .container .blockType dd .txA .subject span{
	font-weight:bold;
}



#sub .subCont .container .blockType dd .txA .price{
	width:100%;
	font-size:24px;
	font-weight:bold;
	font-family:'lato','nanumsquare';
	color:#d30000;
	margin-top:10px;
}


#sub .subCont .container .blockType dd .txA .price .won{
	font-size:16px;
	font-weight:normal;
}


#sub .subCont .container .blockType dd .txA .price .pr{
	font-size:16px;
	font-weight:normal;
	color:#777;
	display:inline-block;
	margin-left:10px;
}


#sub .subCont .container .blockType dd .txA .ctSubs{
	font-size:16px;
	color:#444;
	margin-top:30px;
	word-break:keep-all;
	line-height:26px;
	height:72px;
	overflow:hidden;
}

/*golfgogo - 회사소개*/
#sub .golfgogo{
	padding: 0px 300px 150px 300px;
}


#sub .golfgogo .product{
	max-width:1180px;
	margin:80px auto 0px;
}


#sub .golfgogo .product .subSubj{
	font-size:24px;
	color:#222;
}

#sub .golfgogo .product .subj{
	font-size:30px;
	color:#222;
	font-weight:bold;
	margin-top:10px;
}


#sub .golfgogo .product .productList{
	width:100%;
	display:inline-block;
	margin-top:30px;
}

#sub .golfgogo .product .productList li{
	width:380px;
	display:Inline-block;
	float:left;
	margin-left:20px;
	position:relative;
}

#sub .golfgogo .product .productList li:first-child{
	margin-left:0px;
}

#sub .golfgogo .product .productList li .bg{
	width:100%;
}

#sub .golfgogo .product .productList li .tc{
	width:100%;
	position:absolute;
	bottom:0;
	padding:20px;
	box-sizing:border-box;
}

#sub .golfgogo .product .productList li .tc .enNm{
	font-size:16px;
	color:#c1c1c1;
}

#sub .golfgogo .product .productList li .tc .nm{
	font-size:24px;
	color:#fff;
	margin-top:20px;
	font-weight:bold;
}

#sub .golfgogo .product .productList li .tc .prnm{
	font-size:18px;
	color:#fff;
	margin-top:10px;
}


#sub .golfgogo .doc{
	width:100%;
	background:#f7f7f7;
	margin-top:80px;
	margin-bottom:100px;
	padding:80px 0px;
}



#sub .golfgogo .doc .inner{
	max-width:1180px;
	margin:0 auto;
}

#sub .golfgogo .doc .inner .tit{
	font-size:30px;
	text-align:Center;
	font-weight:bold;
}

#sub .golfgogo .doc .inner .docList{
	width:100%;
	margin-top:30px;
}


#sub .golfgogo .doc .inner .docList li{
	margin:0px 30px;
}


#sub .golfgogo .doc .inner .docList .thum{
	width:100%;
}

#sub .golfgogo .doc .inner .docList .thum img{
	width:100%;
}


#sub .golfgogo .doc .inner .docList .subj{
	font-size:16px;
	margin-top:20px;
	text-align:Center;
}



#sub .golfgogo .doc .inner .docList .slick-prev{
	background:url(../images/sub/docPrev.png) no-repeat;
	width:13px;
	height:24px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:-50px;
	top:50%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}


#sub .golfgogo .doc .inner .docList .slick-next{
	background:url(../images/sub/docNext.png) no-repeat;
	width:13px;
	height:24px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:-50px;
	top:50%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}








/*miyazakiPackage - 마닐라,미야자키 패키지*/


#sub .subCont .container .packageView{
	width:100%;
	padding:50px 0px;
	border-top:1px solid #e5e5e5;
	border-bottom:1px solid #e5e5e5;
	margin-top:15px;
}

#sub .subCont .container .packageView .pvA{
	width:100%;
}

#sub .subCont .container .packageView .pvA .pvVisual{
	width:100%;
}

#sub .subCont .container .packageView .pvA .pvVisual li{
	margin:0px 10px;
}

#sub .subCont .container .packageView .pvA .pvVisual li img{
	width:100%;
}

#sub .subCont .container .packageView .pvA .pvVisual .slick-prev{
	background:url(../images/sub/pvVisualPrev.png) no-repeat;
	width:38px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:-50px;
	top:50%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}


#sub .subCont .container .packageView .pvA .pvVisual .slick-next{
	background:url(../images/sub/pvVisualNext.png) no-repeat;
	width:38px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:-50px;
	top:50%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}


#sub .subCont .container .packageView .pvDeInfo{
	width:100%;
	padding:40px 0px;
	border-bottom:1px solid #e5e5e5;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine{
	width:100%;
	display:inline-block;
}


#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA{
	display:inline-block;
	width:49.5%;
	vertical-align:top;
	padding-right:30px;
	box-sizing:border-box;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subj{
	width:100%;
	font-size:24px;
	font-weight:bold;
	color:#222;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subj .saleBat{
	width:70px;
	border:1px solid #004f86;
	color:#004f86;
	text-align:Center;
	padding:6px 0px;
	border-radius:50px;
	font-size:13px;
	display:inline-block;
	vertical-align:middle;
	margin-top:-5px;
	margin-right:10px;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .subjA .subs{
	width:100%;
	word-break:keep-all;
	font-size:16px;
	color:#444;
	margin-top:10px;
	line-height:24px;
}


#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA{
	display:inline-block;
	width:50%;
	vertical-align:top;
	text-align:Right;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox{
	width:390px;
	display:inline-block;
	border:1px solid #e5e5e5;
	padding:20px;
	box-sizing:border-box;
	text-align:left;
	position:relative;
	cursor:pointer;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .tx{
	font-size:15px;
	color:#444;
	display:inline-block;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .ar{
	width:12px;
	display:Inline-block;
	float:right;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .sbDrop{
	position:absolute;
	margin-top:20px;
	border:1px solid #e5e5e5;
	width:100%;
	left:-1px;
	display:none;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .selectBox .sbDrop li{
	padding:15px 20px;
	font-size:15px;
	background:#fff;
}



#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .bookBtn{
	display:Inline-block;
	padding:21px 30px;
	font-size:15px;
	color:#fff;
	background:#004f86;
}

#sub .subCont .container .packageView .pvDeInfo .dsLine .sbA .bookBtn a{
	color:#fff;
}


#sub .subCont .container .packageView .pvDeInfo .price{
	width:100%;
	margin-top:50px;
	font-size:30px;
	color:#d30000;
	font-weight:bold;
	font-family:'lato','nanumsquare';
}

#sub .subCont .container .packageView .pvDeInfo .price span{
	font-size:16px;
	font-weight:normal;
}


#sub .subCont .container .packageView .pvDeInfo .di{
	width:100%;
	margin-top:10px;
	font-size:16px;
	color:#444;
	line-height:26px;
}

#sub .subCont .container .packageView .pvDeInfo .arT{
	width:100%;
	margin-top:10px;
	font-size:13px;
	color:#777777;
	line-height:21px;
}



#sub .subCont .container .packageView .dtInfoList{
	width:100%;
}

#sub .subCont .container .packageView .dtInfoList dd{
	width:100%;
	padding:50px 0px;
	border-bottom:1px solid #e5e5e5;
}

#sub .subCont .container .manilaPackage .dtInfoList dd:last-child{
	padding:50px 0px 0px;
	border-bottom:0px solid #e5e5e5;
}

#sub .subCont .container .packageView .dtInfoList dd .titA{
	width:15%;
	display:inline-block;
	vertical-align:top;
	font-size:24px;
	color:#222;
	font-weight:bold;
}


#sub .subCont .container .packageView .dtInfoList dd .contA{
	width:84.5%;
	display:inline-block;
	vertical-align:top;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo{
	width:100%;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li{
	width:100%;
	margin-top:30px;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li:first-child{
	margin-top:0px;
}


#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li .subj{
	width:12%;
	display:inline-block;
	vertical-align:top;
	color:#004f86;
	font-size:16px;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .detailInfo li .subs{
	width:87%;
	display:inline-block;
	vertical-align:top;
	color:#777777;
	font-size:16px;
}


#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox{
	width:100%;
	border:1px solid #e5e5e5;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .subjLine{
	width:100%;
	border-bottom:1px solid #e5e5e5;
	background:#f7f7f7;
	padding:20px;
	font-size:18px;
	color:#222;
	font-weight:bold;
	box-sizing:border-box;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox{
	width:100%;
	padding:40px 20px;
	position:relative;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList{
	width:100%;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li{
	width:100%;
	margin-top:50px;
}

#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li:first-child{
	margin-top:0px;
}


#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li .day{
	width:60px;
	background:#fff;
	line-height:60px;
	color:#004f86;
	text-align:center;
	font-weight:bold;
	box-shadow:1px 1px 10px rgba(0,0,0,0.1);
	border-radius:100%;
	font-size:18px;
	display:inline-block;
	vertical-align:middle;
}


#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .scheduleList li .ct{
	width:80%;
	display:inline-block;
	vertical-align:middle;
	padding-left:20px;
	box-sizing:border-box;
	line-height:26px;
	font-size:16px;
}



#sub .subCont .container .packageView .dtInfoList dd .contA .scheduleBox .scheduleListBox .line{
	width:1px;
	height:100%;
	background:#e5e5e5;
	position:absolute;
	margin-left:30px;
	z-index:-1;
	margin-top:0px;
	top:0;
}


#sub .subCont .container .packageView .dtInfoList dd .contA .alrText{
	width:100%;
	text-align:right;
	margin-top:20px;
	font-size:16px;
	color:#777;
	line-height:26px;
}


#sub .subCont .container .packageView .anCClist{
	width:100%;
	padding:50px 0px;
	display:inline-block;
	border-bottom:1px solid #e5e5e5;
}

#sub .subCont .container .packageView .anCClist dd{
	width:380px;
	display:inline-block;
	vertical-align:top;
	float:left;
	margin-left:20px;
}

#sub .subCont .container .packageView .anCClist dd:first-child{
	margin-left:0px;
}

#sub .subCont .container .packageView .anCClist dd .thum{
	width:100%;
}

#sub .subCont .container .packageView .anCClist dd .thum img{
	width:100%;
}

#sub .subCont .container .packageView .anCClist dd .subj{
	width:100%;
	font-size:20px;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	font-weight:bold;
	color:#222;
	margin-top:30px;
}

#sub .subCont .container .packageView .anCClist dd .enSubj{
	width:100%;
	font-size:16px;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	color:#777777;
	font-family:'lato';
	margin-top:5px;
}

#sub .subCont .container .packageView .anCClist dd .subs{
	font-size:16px;
	color:#444444;
	line-height:24px;
	margin-top:20px;
	height:72px;
	overflow:hidden;
	word-break:keep-all;
}


#sub .subCont .container .packageView .anCClist dd .diList{
	width:100%;
	margin-top:30px;
	padding-top:30px;
	border-top:1px solid #e5e5e5;
}

#sub .subCont .container .packageView .anCClist dd .diList li{
	width:100%;
	margin-top:10px;
}

#sub .subCont .container .packageView .anCClist dd .diList li:first-child{
	margin-top:0px;
}


#sub .subCont .container .packageView .anCClist dd .diList .subj{
	width:12%;
	display:inline-block;
	vertical-align:top;
	font-size:16px;
	font-weight:normal;
	color:#004f86;
	margin:0;
	line-height:24px;
}


#sub .subCont .container .packageView .anCClist dd .diList .subs{
	width:86%;
	display:inline-block;
	vertical-align:top;
	font-size:16px;
	font-weight:normal;
	color:#444444;
	margin:0;
	height:auto;
	line-height:24px;
	overflow:Hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}

#sub .subCont .container .packageView .hotelIn{
	width:100%;
	margin-top:50px;
}

#sub .subCont .container .packageView .hotelIn .hotelInVisual{
	width:48%;
	display:inline-block;
	vertical-align:middle;
}

#sub .subCont .container .packageView .hotelIn .hotelInVisual li{
	width:100%;
	position:relative;
}

#sub .subCont .container .packageView .hotelIn .hotelInVisual li .thum{
	width:100%;
}

#sub .subCont .container .packageView .hotelIn .hotelInVisual li .thum img{
	width:100%;
}

#sub .subCont .container .packageView .hotelIn .hotelInVisual li .nm{
	width:100%;
	position:absolute;
	bottom:0;
	padding:20px;
	box-sizing:border-box;
	background:rgba(0,0,0,0.5);
	color:#fff;
	font-size:18px;
}




#sub .subCont .container .packageView .hotelIn .hotelInVisual .slick-prev{
	background:url(../images/sub/hotelInPrev.png) no-repeat;
	width:50px;
	height:50px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:-561px;
	top:0%;
	z-index:2;
	cursor:pointer;
}


#sub .subCont .container .packageView .hotelIn .hotelInVisual .slick-next{
	background:url(../images/sub/hotelInNext.png) no-repeat;
	width:50px;
	height:50px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:-610px;
	top:0%;
	z-index:2;
	cursor:pointer;
}



#sub .subCont .container .packageView .hotelIn .inEx{
	width:48%;
	display:inline-block;
	vertical-align:middle;
	margin-left:3.5%;
}

#sub .subCont .container .packageView .hotelIn .inEx .subj{
	font-size:28px;
	color:#222;
	font-weight:bold;
}

#sub .subCont .container .packageView .hotelIn .inEx .enSubj{
	font-size:16px;
	color:#777;
	font-family:'lato';
	margin-top:5px;
}

#sub .subCont .container .packageView .hotelIn .inEx .subs{
	font-size:18px;
	color:#444444;
	margin-top:30px;
	line-height:26px;
	word-break:keep-all;
}









/*clarkGolf - 골프장정보*/



#sub .subCont .container .golfList{
	width:100%;
	margin-top:15px;
}


#sub .subCont .container .golfList dd{
	width:100%;
	padding:50px 0px 100px;
	box-sizing:border-box;
	border-bottom:1px solid #e5e5e5;
}

#sub .subCont .container .golfList dd:first-child{
	border-top:1px solid #222;
}


#sub .subCont .container .golfList dd .visualA{
	width:32%;
	display:inline-block;
	vertical-align:top;
}

#sub .subCont .container .golfList dd .visualA .num{
	font-size:30px;
	font-family:'lato';
	z-index:1;
	position:relative;
}

#sub .subCont .container .golfList dd .visualA .golfLoVisual{
	width:100%;
	margin-top:-15px;
	position:relative;
	/* z-index: -1; */
}
/* #sub .subCont .container .golfList dd .visualA .golfLoVisual div.slick-slide{
	margin:0px 3px;
} */

#sub .subCont .container .golfList dd .visualA .golfLoVisual img{
	width:100%;
}



#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-prev{
	background:url(../images/sub/visualAPrev.png) no-repeat;
	width:38px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:0px;
	bottom:-50px;
	z-index:1;
	cursor:pointer;
}

#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-next{
	background:url(../images/sub/visualANext.png) no-repeat;
	width:38px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:0px;
	bottom:-50px;
	z-index:1;
	cursor:pointer;
}

#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-dots{
	width:100%;
	text-align:center;
	position:absolute;
	/* margin-top:20px; */
	margin-top:27px;
	overflow: hidden;
}
/* 
#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-dots li{
	display:none;
}

#sub .subCont .container .golfList dd .visualA .golfLoVisual .slick-dots li.slick-active{
	display:block;
} */

#sub .golfLoVisual .slick-dots.slick-thumb {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
}
  
#sub .golfLoVisual .slick-dots.slick-thumb li {
	width: 6px;
	height: 6px;
	margin: 0 3px;
	border-radius: 999px;
	transition: all 0.5s ease-in-out;
	will-change: width;
	background-color: black;
}
  
#sub .golfLoVisual .slick-dots.slick-thumb li.slick-active {
	/* background-color: red; */
	background-color: #004f85;
	width: 24px;
}

#sub .golfLoVisual .slick-dots.slick-thumb li a {	
	cursor: pointer;
}







#sub .subCont .container .golfList dd .ta{
	width:67%;
	display:inline-block;
	vertical-align:top;
	padding-left:50px;
	box-sizing:border-box;
	margin-top:15px;
}


#sub .subCont .container .golfList dd .ta .nm{
	font-size:24px;
	font-weight:bold;
	color:#222222;
	line-height:34px;
}

#sub .subCont .container .golfList dd .ta .inList{
	width:100%;
	margin-top:30px;
}


#sub .subCont .container .golfList dd .ta .inList li{
	width:100%;
	margin-top:15px;
}

#sub .subCont .container .golfList dd .ta .inList li .subjArea{
	width:20%;
	display:inline-block;
	vertical-align:top;
}

#sub .subCont .container .golfList dd .ta .inList li .subjArea .ico{
	width:21px;
	display:inline-block;
	vertical-align:middle;
}

#sub .subCont .container .golfList dd .ta .inList li .subjArea .subj{
	font-size:16px;
	color:#004f86;
	display:inline-block;
	vertical-align:middle;
	margin-left:6px;
}

#sub .subCont .container .golfList dd .ta .inList li .subs{
	width:79%;
	display:inline-block;
	vertical-align:top;
	font-size:16px;
	color:#444;
	line-height:24px;
}






/*clarkEnjoy - 클락즐기기*/

#sub .subCont .enjoyList slick-slide:not(.slick-center){
	position:relative;
	display:inline-block;
	/* transform:scale(0.9); */
	transform:scale(0.9);
}

#sub .subCont .enjoyList .slick-center{
	/* transform:scale(1.05); */
	transform:scale(1.15);
}

#sub .subCont .enjoyList .slick-center .thum{
	/* transform:scale(1.05); */
	transform:scale(1.15);
}




#sub .subCont .container .enjoyList{
	width:100%;
	margin-top:15px;
}

#sub .subCont .container .enjoyList dd{
	width:100%;
	padding:50px 0px;
	border-bottom:1px solid #e5e5e5;
}

#sub .subCont .container .enjoyList dd:first-child{
	border-top:1px solid #222;
}



#sub .subCont .container .enjoyList dd .enjoyVisual{
	width:100%;
}

#sub .subCont .container .enjoyList dd .enjoyVisual li img{
	width:100%;
}


#sub .subCont .container .enjoyList dd .enjoyVisualNav{
	width:100%;
	margin-top:10px;
}


#sub .subCont .container .enjoyList dd .enjoyVisualNav li{
	margin:0px 5px;
}


#sub .subCont .container .enjoyList dd .enjoyVisualNav li img{
	width:100%;
}


#sub .subCont .container .enjoyList dd .enjoyVisualNav .slick-prev{
	background:url(../images/sub/visualAPrev.png) no-repeat;
	width:38px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:-50px;
	top:50%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}

#sub .subCont .container .enjoyList dd .enjoyVisualNav .slick-next{
	background:url(../images/sub/visualANext.png) no-repeat;
	width:38px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:-50px;
	top:50%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}







#sub .subCont .container .enjoyList dd .ta{
	width:100%;
	margin-top:40px;
}


#sub .subCont .container .enjoyList dd .ta .subject{
	font-size:28px;
	color:#222;
	font-weight:bold;
	line-height:38px;
}

#sub .subCont .container .enjoyList dd .ta .subSubj{
	font-size:18px;
	color:#444444;
	margin-top:10px;
	line-height:28px;
}


#sub .subCont .container .enjoyList dd .ta .subs{
	font-size:16px;
	color:#444444;
	margin-top:30px;
	line-height:26px;
}







/*clarkHotel - 호텔*/




#sub .subCont .container .hotelList{
	width:100%;
	margin-top:15px;
}
#sub .subCont .container .hotelList dd{
	width:100%;
	padding:50px 0px;
	border-bottom:1px solid #e5e5e5;
}

#sub .subCont .container .hotelList dd:first-child{
	border-top:1px solid #222;
}


#sub .subCont .container .hotelList dd .thumArea{
	width:100%;
	display:inline-block;
	
}


#sub .subCont .container .hotelList dd .thumArea .bThum{
	width:580px;
	display:Inline-block;
	float:left;
}

#sub .subCont .container .hotelList dd .thumArea .bThum img{
	width:100%;
}


#sub .subCont .container .hotelList dd .thumArea .sThumList{
	width:580px;
	display:Inline-block;
	margin-left:20px;
	float:Left;
}

#sub .subCont .container .hotelList dd .thumArea .sThumList div{
	width:280px;
	display:inline-block;
	float:left;
	margin-left:20px;
	margin-top:20px;
}


#sub .subCont .container .hotelList dd .thumArea .sThumList div img{
	width:100%;
}

#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(2n+1){
	margin-left:0px;
}

#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(1),
#sub .subCont .container .hotelList dd .thumArea .sThumList div:nth-child(2){
	margin-top:0px;
}


#sub .subCont .container .hotelList dd:nth-child(2n) .thumArea .sThumList{
	margin-left:0px;
	margin-right:20px;
}




#sub .subCont .container .hotelList dd .ta{
	width:100%;
	margin-top:40px;
}






#sub .subCont .container .hotelList dd .ta .nmLine{
	width:100%;
}

#sub .subCont .container .hotelList dd .ta .nmLine .nm{
	font-size:28px;
	display:inline-block;
	vertical-align:bottom;
	color:#222;
	font-weight:bold;
}
#sub .subCont .container .hotelList dd .ta .nmLine .en{
	font-size:16px;
	display:inline-block;
	vertical-align:bottom;
	color:#777777;
	font-family:'lato';
	margin-left:10px;
}

#sub .subCont .container .hotelList dd .ta .po{
	font-size:18px;
	color:#444444;
	margin-top:10px;
	line-height:28px;
}


#sub .subCont .container .hotelList dd .ta .subs{
	font-size:16px;
	color:#444444;
	margin-top:30px;
	line-height:26px;
	word-break:keep-all;
}


































